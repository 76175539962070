const en = {
  label: {
    //-----------------------------------------------------------------------------------------------------------
    //ETIQUETAS COMPUESTAS
    //----------------------------------------------------------------------------------------------------------
    CertificationDocumentDate:"CERTIFICATION DATE",
    indicateQuantityAccesory:"YOU MUST INDICATE QUANTITY WHEN SELECTING ACCESSORY",
    quantityOfAccessoryBeNegative:"QUANTITY OF ACCESSORY CANNOT BE NEGATIVE",
    notSerialized: "NOT SERIALIZED",
    Master: 'MASTER',
    automaticCalculation:'AUTOMATIC CALCULATION',
    perEquipment:'PER EQUIPMENT',
    newActivity: 'NEW PORT ACTIVITY',
    modifActivity: 'EDIT PORT ACTIVITY',
    registerActivity: 'REGISTER PORT ACTIVITY',
    portActivity: 'PORT ACTIVITY',
    nameActivity: 'NAME PORT ACTIVITY',
    listActivity: 'PORTS ACTIVITIES LIST',
    listBrand: 'BRANDS / MODELS  LIST',
    listScale: 'TRUCK WEIGHING SCALE LIST',
    listCrane: 'CRANES TYPES LIST',
    cranesTypesGeneralList: 'CRANES TYPES GENERAL LIST',
    listStowage: 'STOWAGE LIST',
    listLost: 'DELAY TIMES LIST',
    listLostTime: 'DELAY TIMES LIST',
    listType: 'TYPE LIST',
    listVessel: 'VESSELS LIST',
    vesselsGeneralList: 'VESSELS GENERAL LIST',
    listTypeVessel: 'VESSELS TYPE LIST',
    portList: 'PORT LIST',
    berthList: 'BERTH LIST',
    companyBranchList: 'COMPANY/BRANCH LIST',
    locationList: 'LOCATION LIST',
    moduleList: 'MODULES LIST',
    moduleRoleList: 'MODULES ROLE LIST',
    roleList: 'ROLE LIST',
    generalRoleList: 'GENERAL ROLE LIST',
    specificRoleList: 'SPECIFIC ROLE LIST',
    generalRole: 'GENERAL ROLE',
    specificRole: 'SPECIFIC ROLE',
    portName: 'PORT NAME',
    portCode: 'PORT CODE',
    lostTimeType: 'DELAY TIME TYPE',
    lostTimeTypeName: 'DELAY TIME TYPE NAME',
    lostTimeName: 'DELAY TIME NAME',
    TdrCode: 'TDR CODE',
    craneType: 'CRANE TYPE',
    craneTypeName: 'CRANE TYPE NAME',
    craneName: 'CRANE NAME',
    vesselType: 'VESSEL TYPE',
    vesselName: 'VESSEL NAME',
    vesselTypeName: 'VESSEL TYPE NAME',
    vesselTypeDescription: 'VESSEL TYPE DESCRIPTION',
    vesselCrane: 'VESSEL CRANE',
    moduleType: 'MODULE TYPE',
    maxHeigth: 'MAX HEIGTH',
    maxWeight: 'MAX WEIGHT',
    maxRange: 'MAX RANGE',
    stowageWorkShift: 'STOWAGE WORK SHIFT',
    workShifts: 'WORK SHIFTS',
    workShift: 'WORK SHIFT',
    WorkShift: 'WORK SHIFT',
    shiftName: 'SHIFT NAME',
    turnName: 'TURN NAME',
    fromHour: 'FROM HOUR',
    hourFrom: 'HOUR (FROM)',
    DFrom: 'D/H (FROM)',
    toHour: 'TO HOUR',
    hourTo: 'HOUR (TO)',
    DTo: 'D/H (TO)',
    startHour: 'HOUR (START)',
    endHour: 'HOUR (END)',
    stowageTurn: 'STOWAGE TURN ',   
    stowageMov: 'STOWAGE MOVEMENTS',
    stowageMovement: 'STOWAGE MOVEMENT',
    stowageMovementTransaction: 'STOWAGE MOVEMENT TRANSACTION',
    stowageRole: 'STOWAGE ROLE',
    stowageSupplies: 'STOWAGE SUPPLIES',
    transactionMovs: 'MOVEMENTS TRANSACTIONS',
    transactionMov: 'MOVEMENTS TRANSACTION',
    movementName: 'MOVEMENT NAME',
    movementType: 'MOVEMENT TYPE ',
    transactionName: 'TRANSACTION NAME',
    transactionType: 'TRANSACTION TYPE',
    supplieName: 'SUPPLIE NAME',
    groupName: 'GROUP NAME',
    groupDescription: 'GROUP DESCRIPTION',
    lostTime: 'DELAY TIME',
    hatchCover: 'HATCH COVER',
    hatchCoverName: 'HATCH COVER NAME',
    hatchCoverId: 'HATCH COVER ID',
    hatchCoverType: 'HATCH COVER TYPE',
    holdName: 'HOLD NAME',
    underCover: 'UNDER COVER',
    dustCover: 'DUST COVER',
    availableAssign: 'AVAILABLE TO ASSIGN',
    vesselInfo: 'VESSEL INFO',
    vesselDocumentation: 'VESSEL DOCUMENTATION',
    onDeck: ' ON DECK',
    Outside:'OUTSIDE',
    Inside: 'INSIDE',
    chooseFile: 'CHOOSE FILE',
    bulkLoad: 'BULK LOAD',
    seeFormat: 'SEE FORMAT',
    berthLine: 'BERTH LINE',
    associatedLine: 'ASSOCIATED LINES',
    associatedPort: 'ASSOCIATED PORT',
    associatedRol: 'ASSOCIATED ROL',
    relatedLine: 'RELATED LINES',
    representativeLine: 'REPRESENTATIVE LINE',
    initialDate: 'INITIAL DATE',
    finalDate: 'FINAL DATE',
    findContainersKey:'FIND CONTAINERS WITH A SEARCH KEY ',
    findBlKey:'FIND BL WITH A SEARCH KEY',
    startDate: 'START DATE',
    endDate: 'END DATE',
    events: 'EVENTS',
    date: 'DATE',
    bulkCargo: 'BULK CARGO MERCHANDISE',
    originPort: 'ORIGIN PORT',
    destinationPort: 'DESTINATION PORT',
    arrivalTime: 'ARRIVAL TIME',
    arrivalDate: 'ARRIVAL DATE',
    sailTime: 'SAIL TIME',
    departureTime: 'DEPARTURE TIME',
    departureDate: 'DEPARTURE DATE',
    basicData: 'BASIC DATA',
    mainPhone: 'MAIN PHONE',
    secondaryPhone: 'SECONDARY PHONE',
    mainEmail: 'MAIN EMAIL',
    secondaryEmail: 'SECONDARY EMAIL',
    badFormat: 'BAD FORMAT',
    businessProcess: 'BUSINESS PROCESS',
    masterDirectory: 'MASTER DIRECTORY',
    menuIdentifier: 'MENU IDENTIFIER',
    proceduralManual: 'PROCEDURAL MANUAL',
    userManual: 'USER MANUAL',
    user: 'USER',
    userId: 'USER ID',
    userList: 'USER LIST',
    userType: 'TYPE OF USER',
    userTypeName: 'USER TYPE NAME',
    enterSearch: 'ENTER SEARCH',
    accessRoute: 'ROUTE ACCESS',
    interfaceAction: 'INTERFACE ACTION',
    noData: 'NO DATA',
    recordsPerPage: 'RECORDS PER PAGE',
    perPage: ' PER PAGE',
    noResultsFound: 'NO RESULTS FOUND',
    noRecordsAvailble: 'NO RECORDS AVAILABLE',
    routeDash: 'ROUTE DASH INIT',
    route: 'ROUTE',
    unitSize: 'UNIT SIZE',
    sizeUnit: 'SIZE UNIT',
    measureUnit: 'MEASURE UNIT',
    measureUnitType: 'MEASURE UNIT TYPE',
    gpoSupply: 'GROUP SUPPLY',
    payrollType: 'PAYROLL TYPE',
    employeeRole: 'EMPLOYEE BY ROLE',
    employeesRole: 'EMPLOYEES BY ROLE',
    //DIEGO
    transversalView: 'CROSS SECTIONAL VIEW',
    previouslyAssigned: 'PREVIOUSLY ASSIGNED',
    associateBayPair: 'ASSOCIATE BAY PAIR',
    baySelected: 'BAY SELECTED',
    notAvailable: 'NOT AVAILABLE',
    bay: 'BAY',
    tierRows: 'TIER-ROWS',
    positions: 'POSITIONS',
    download: 'DOWNLOAD',
    atLeast: 'AT LEAST',
    selectIt: 'SELECT',
    holdType: 'HOLD TYPE',
    changeStatusQuestion: 'Are you sure to make the status change for the record',
    changeViewQuestion: 'Are you sure to make the view change for the record',
    changeElementQuestion: 'Are you sure to make the element change for the record',
    deleteQuestion: 'Are you sure to delete the record',
    fileSizeExceedsAllowedLimit: 'file size exceeds allowed limit.',
    fileTypeIsNotAllowed: 'file type is not allowed.',
    touchToSelectPicture: 'touch to select picture.',
    clickHereToSelectPicture: 'Click here to select a <br> Picture.',
    deviceDoesNotSupportFileUploads: 'Device does not support file uploads.',
    operationCancelled: 'OPERATION CANCELLED',
    operationType: 'OPERATION TYPE',
    CProa: 'BOW DRAFT',
    CPopa: 'STERN DRAFT',
    draftForward: 'DRAFT FORWARD',
    draftAft: 'DRAFT AFT',
    summerDWT: 'SUMMER DWT',
    bowDraft: 'BOW DRAFT',
    sternDraft: 'STERN DRAFT',
    LWL: "L.W.L",
    deckCrane: "DECK CRANE?",
    fuelOilCapacity: "FUEL OIL CAPACITY",
    documentDescription: "DOCUMENT DESCRIPTION",
    grainCapacity: "GRAIN CAPACITY",
    baleCapacity: "BALE CAPACITY",
    documentType: 'DOCUMENT TYPE',
    document: 'DOCUMENT',
    removeDocument: 'REMOVE DOCUMENT',
    transacType: 'TRANSACTION TYPE',
    details: 'DETAILS',
    cancelUpload: 'CANCEL UPLOAD',
    mustSelectDocType: 'Must select a Document Type',
    docTypeInvalid: 'INVALID DOCUMENT TYPE.',
    information: 'INFORMATION',
    alerts: 'ALERTS',
    errors: 'ERRORS',
    navReports: 'REPORTS',
    navConfig: 'SETTING',
    navViews: 'VIEWS',
    views: 'VIEWS',
    navPersonalReport: 'CUSTOMIZE REPORTS',
    values: 'VALUES',
    viewType: 'VIEW TYPE',
    filterBy: 'FILTER BY',
    filteredBy: 'FILTER BY',
    page: 'PAGE',
    of: 'OF',
    legend: 'LEGEND',
    preferences: 'PREFERENCES',
    print: 'PRINT',
    columns: 'COLUMNS',
    rows: 'ROWS',
    next: 'NEXT',
    last: 'LAST',
    first: 'FIRST',
    previous: 'PREVIOUS',
    selectFilter: 'Select a Filter',
    viewBy: 'VIEW BY',
    Mode: 'MODE',
    shippingAgency: 'SHIPPING AGENCY',
    shippingLineName: 'SHIPPING LINE NAME',
    shippingLineRepresentative: "SHIPPING LINE REPRESENTATIVE",
    errorsPleaseCheck: 'There are errors, please check.',
    containersList: 'CONTAINERS LIST',
    containerGroup: 'GROUP',
    containerCode: 'CONTAINER CODE',
    blPositioning: 'BL POSITIONING',
    containerType: 'CONTAINER TYPE',
    codesPerDocuments: 'CODES PER DOCUMENTS',
    containersCodesPerDocuments: 'CONTAINERS CODES PER DOCUMENTS',
    exportToExcel: 'EXPORT INFO TO EXCEL',
    serialNumber: 'SERIAL NUMBER',
    damageType: 'DAMAGE TYPE',
    damageTypes: 'DAMAGE TYPE',
    damagesType: 'DAMAGE TYPE',
    damageTypeRegistration: 'NEW DAMAGE TYPE',
    damageTypeName: 'DAMAGE TYPE NAME',
    verifyFields: 'Verify  fields',
    eir: 'eir',
    eirList: 'EIR\'S LIST',
    eirType: 'EIR TYPE',
    eirTypeName: 'EIR TYPE NAME',
    eirTransaction: 'EIR TRANSACTION',
    eirTypeTransaction: 'EIR TYPE TRANSACTION',
    eirTypeTransactionName: 'EIR TYPE TRANSACTION NAME',
    cargoGroup: 'CARGO GROUP',
    cargoGroupName: 'CARGO GROUP NAME',
    cargoGroupList: 'CARGO GROUP LIST',
    listMeasurementUnits: 'LIST MEASUREMENT UNITS',
    measurementUnits: 'MEASUREMENT UNITS',
    measurementUnit: 'MEASUREMENT UNIT',
    MeasurementUnit: 'MEASUREMENT UNIT',
    measurementUnitList: 'MEASUREMENT UNIT LIST',
    unitsOfMeasurement: 'LIST MEASUREMENT UNITS',
    cargoState: 'CARGO STATE',
    stateName: 'STATE NAME',
    stateDescription: 'STATE DESCRIPTION',
    packaging: 'PACKAGING',
    packagings: 'PACKAGINGS',
    packagingsSerial: 'PACKAGINGS / SERIALS',
    packagingName: 'PACKAGING NAME',
    packagingRelationship: 'PACKAGING RELATIONSHIP',
    associatePackaging: 'ASSOCIATE PACKAGING',
    commodity: 'COMMODITY',
    commodityName: 'COMMODITY NAME',
    commodityDescription: 'COMMODITY DESCRIPTION',
    commodityRelationship: 'COMMODITY RELATIONSHIP',
    commodityHandlingDocumentation: 'COMMODITY HANDLING DOCUMENTATION',
    bulkCargoList: 'BULK CARGO LIST',
    bulkCargoState: 'BULK CARGO STATE',
    bulkCargoCommodity: 'BULK CARGO COMMODITY',
    bulkCargoRelationship: 'BULK CARGO RELATIONSHIP',
    newBulkCargoRegistration: 'NEW BULK CARGO REGISTRATION',
    relationshipBulkCargoCommodity: 'RELATIONSHIP BULK CARGO COMMODITY',
    applyToAll: 'APPLY TO ALL',
    heading: 'HEADING',
    generalCargoGroups: 'GENERAL CARGO GROUPS',
    generalCargoGroup: 'GENERAL CARGO GROUP',
    generalCargoGroupClassification: 'GENERAL CARGO GROUP CLASSIFICATION',
    generalCargoList: 'GENERAL CARGO LIST',
    generateTransaction: 'GENERATE TRANSACTION',
    associateGeneralGroupCargoPackaging: 'ASSOCIATE GENERAL GROUP CARGO PACKAGING',
    unitType: 'UNIT TYPE',
    unitTypes: 'UNIT TYPES',
    weightRange: 'WEIGHT RANGE',
    weightLimit: 'WEIGHT LIMIT',
    lessOrEqualThan: 'LESS OR EQUAL THAN',
    greaterOrEqualThan: 'GREATER OR EQUAL THAN',
    manualsHandlingCommodity: 'MANUALS HANDLING COMMODITY',
    handlingManuals: 'HANDLING MANUALS',
    statusName: 'STATUS NAME',
    planificacionEstiba: 'PLANNING',
    generalData: 'GENERAL DATA',
    dragAndDropToUploadContent: 'Drag and drop to upload content',
    orClickToSelectFileFromYourComputer: 'or click to select a file from your computer',
    TotalHours: 'TOTAL HOURS',
    EstimatedHours: 'ESTIMATED HOURS',
    craneWorkShifts: 'CRANE WORK SHIFTS',
    CraneWorkShift: 'CRANE WORK SHIFT',
    machineCondition: 'MACHINE CONDITION',
    machineSupplier: 'MACHINE SUPPLIER',
    machineType: 'MACHINE TYPE',
    machineName: 'MACHINE NAME',
    inactivateCranePlanification: 'INACTIVATE CRANE PLANIFICATION',
    inactivateMachinePlanification: 'INACTIVATE MACHINE PLANIFICATION',
    assignCraneWorkShift: 'ASSIGN CRANE WORK SHIFT',
    assignMachineWorkShift: 'ASSIGN MACHINE WORK SHIFT',
    electronicData: 'ELECTRONIC DATA',
    liftingSpeed: 'LIFTING SPEED',
    loweringSpeed: 'LOWERING SPEED',
    maxLoadCapacity: 'MAX LOAD CAPACITY',
    onTheLine: 'ON THE LINE',
    maximumRadio: 'MAXIMUM RADIO',
    loadDischargeOperations: 'LOAD/DISCHARGE OPERATIONS',
    deleteFile: 'Are you sure to delete the file?',
    isoCode: 'ISO CODE',
    isoStandar: 'ISO STANDARD',
    Estandar: 'STANDARD',
    forAllTypeLoadsInGeneral: 'FOR ALL TYPES OF LOAD IN GENERAL',
    itsNotColour: 'IT\'S NOT A COLOUR',
    elementName: 'ELEMENT NAME',
    accesoryName: 'ACCESSORY NAME',
    componentName: 'COMPONENT NAME',
    resume: 'RESUME',
    plans: 'LETTER PLAN',
    transacModalTitle: 'TRANSACTIONS GENERATE BY',
    removeContainerOne: 'ARE YOU SURE TO REMOVE CONTAINER',
    removeContainerTwo: 'IN BAY POSITION',
    deviceList: 'DEVICE LIST',
    device: 'DEVICE',
    registerDevice: 'REGISTER NEW DEVICE',
    editDevice: 'EDIT DEVICE',
    macAddress: 'MAC ADDRESS',
    deviceName: 'DEVICE NAME',
    deviceType: 'DEVICE TYPE',
    operatingSystem: 'OPERATING SYSTEM',
    storageCapacity: 'STORAGE CAPACITY',
    screenSize: 'SCREEN SIZE',
    largo: 'LENGTH',
    resolution: 'RESOLUTION',
    codImei: 'IMEI CODE',
    validateDevice: 'VALIDATE DEVICE',
    depth: 'DEPTH',
    manufacturer: 'MANUFACTURER',
    levelPreference: 'LEVEL PREFERENCE',
    assignDevice: 'DEVICE ASSIGN',
    responsible: 'RESPONSIBLE',
    transfer: 'TRANSFER',
    hatch: 'HATCH COVERS',
    reeferSockets: 'REEFER SOCKETS',
    socket: 'SOCKET',
    totalQuantity: 'TOTAL QUANTITY',
    vesselSheet: 'VESSEL DATA SHEET',
    bale: 'BALE',
    baleOnGrain: 'BALE ON GRAIN',
    baleOnDeck: 'BALE ON DECK',
    stacking: 'STACKING',
    stack: 'STACK',
    upTier: 'UP TIER',
    row: 'ROW',
    inHold: 'IN HOLD',
    onDeck2: 'ON DECK',
    generalFilter: 'GENERAL FILTER',
    multipleFilter: 'MULTIPLE FILTER',
    matching: 'MATCHING',
    executed: 'EXECUTED',
    today: 'TODAY',
    delays: 'DELAYS',
    dateStartOperations: 'D. BEGIN OPER.',
    dateEndOperations: 'D. LAST OPERATION',
    endOperations: 'LAST OPERATION',
    empty: 'EMPTY',
    full: 'FULL',
    clearFilters: 'CLEAR FILTERS',
    ship_ship: 'SHIP-SHIP',
    shipToShip: 'SHIP TO SHIP',
    shipLandShip:'SHIP LAND TO SHIP',
    ship_land_ship: 'SHIP-LAND-SHIP',
    shipment: 'SHIPMENT',
    stowagePlanning: 'STOWAGE PLANNING',
    stevedore: 'STEVEDORE',
    cranesMovements: 'CRANE MOVEMENTS',
    averageMovementsPerHrs: 'AVERAGE MOVEMENTS PER HOUR',
    DischargeMovements: 'DISCHARGE MOVEMENTS',
    LoadMovements: 'LOAD MOVEMENTS',
    HatchMovements: 'HATCH COVER MOVEMENTS',
    MovsNumbers: 'NUMBER OF MOVEMENTS',
    vesselPlanning: 'VESSEL PLANNING',
    mobilized:'MOBILIZED',
    Restow:'RESTOW',
    TimeLost: "DELAY",
    Scale: "TRUCK WEIGHING SCALE",
    nameScale: "TRUCK WEIGHING SCALE NAME",
    connectedTo: 'CONNECTED TO',
    connected: 'CONNECTED',
    readingFile: 'READING FILE',
    errosHourPleaseCheck: 'Hour errors, please check',
    errosDatePleaseCheck: 'Date errors, please check',
    applyPlan: 'APPLY PLAN',
    refresh: 'REFRESH',
    format: 'FORMAT',
    resourcesQty: 'RESOURCES',
    rightRows: 'RIGHT ROWS',
    wrongRows: 'WRONG ROWS',
    bankDetails: 'BANKS DETAILS',
    the: 'THE',
    successUpload: 'HAS BEEN UPLOADED SUCCESSFULLY',
    errorsStaff: 'DOWNLOAD THE EXCEL FILE WITH THE WRONG ROW FOR CORRECTION',
    column: 'COLUMN',
    deactivateFile: 'Are you sure to deactivate the file?',
    phone: 'PHONE',
    bank: 'BANK',
    currency: 'CURRENCY',
    accountType: 'ACCOUNT TYPE',
    accountNumber: 'ACCOUNT #',
    holder: 'HOLDER',
    alias: 'ALIAS',
    bankAccount: 'BANK ACCOUNT',
    P2P: 'P2P',
    initialInventory: 'INITIAL INVENTORY',
    inventory: 'INVENTORY',
    commodityType: 'COMMODITY TYPE',
    certificationDate: 'CERTIFICATION DATE',
    clientRif: 'CLIENT RIF',
    inventoryContains: 'DESCRIPTION OF GOODS',
    sureNotSerializePackaging:'ARE YOU SURE NOT TO SERIALIZE THE PACKAGING?',
    totalWeight: 'TOTAL WEIGHT',
    reportStaffList: 'REPORT - STAFF LIST',
    netWeight: 'NET WEIGHT',
    manualLoad: 'MANUAL LOAD',
    historic: 'HISTORIC',
    reception: 'RECEPTION',
    scrapReception: 'SCRAP RECEPTION',
    entryOrders: 'ENTRY ORDERS',
    massiveLoading: 'MASSIVE LOADING',
    newReception: 'NEW RECEPTION',
    editReception: 'EDIT RECEPTION',
    nroControl: 'CONTROL Nº',
    idDriver: 'ID DRIVER',
    nroGuide: 'GUIDE Nº',
    carPlate: 'CAR PLATE',
    carrierCompany: 'CARRIER COMPANY',
    carrierRif: 'CARRIER RIF',
    receptionDate: 'RECEPTION DATE',
    truckScale: 'TRUCK WEIGHING SCALE',
    typeMovement: 'MOVEMENT TYPE',
    metalScrapReception: "METAL SCRAP RECEPTION LIST",
    listReception: "RECEPTION LIST",
    scrapReception: "METAL SCRAP RECEPTION",
    circuit: 'CIRCUIT',
    circuits: 'CIRCUITS',
    circuitList: 'CIRCUIT LIST',
    circuitName: 'CIRCUIT NAME',
    circuitNameLabelEn: 'CIRCUIT NAME (EN)',
    circuitNameLabelEs: 'CIRCUIT NAME (ES)',
    mandatory: 'REQUIRED',
    require: 'REQUIRE',
    radioactivity: 'RADIOACTIVITY',
    radioactivityLevel: 'RADIOACTIVITY LEVEL',
    machineryInYard: 'YARD MACHINERY',
    movementsInYard: 'MOVEMENTS IN YARD',
    selectRequireOption: 'AT LEAST 1 REQUIRE OPTION MUST BE SELECTED',
    selectDateOption: 'DATE OPTION REQUIRED',
    entryDate: 'ENTRY DATE',
    outDate: 'OUT DATE',
    consignee: 'CLIENT',
    declaredConsignee: "DECLARED CONSIGNEE",
    commodity: 'COMMODITY',
    stowagePlan: 'STOWAGE PLAN',
    importer: 'IMPORTER',
    exporter: 'EXPORTER',
    rifExporter: 'EXPORTER RIF',
    rifImporter: 'IMPORTER RIF',
    rifConsignee: 'CONSIGNEE RIF',
    notifyParty: 'NOTIFY PARTY',
    seller: 'SELLER',
    cargoType: 'CARGO TYPE',
    viscosity: 'VISCOSITY',
    temperature: 'TEMPERATURE',
    estimatedToShip: 'ESTIMATED TO SHIP',
    actualInventory: 'ACTUAL INVENTORY',
    available: 'AVAILABLE',
    occupied: 'OCCUPIED',
    assignationPlanned: 'ASSIGNATION PLANNED',
    assignBl: 'ASSIGN BL',
    assignTon: 'ASSIGN TON',
    tonAssigned: 'TON ASSIGNED',
    clientExporter: 'EXPORTER CLIENT',
    clientImporter: 'IMPORTER CLIENT',
    billOfLading: 'BILL OF LADING',
    plannedAssignment: 'PLANNED ASSIGNMENT',
    declared: 'DECLARED',
    assignation: 'ASSIGN',
    loadCondition: 'LOAD - CONDITIONS',
    availabilityByBl: 'AVAILABILITY BY BL',
    equipment: 'EQUIPMENT',
    planned: 'PLANNED',
    loadPortSituation: 'LOAD',
    executedPortSituation: 'EXECUTED',
    BTB: 'STS',
    twinBoxes: 'TWISTLOCK BOX',
    ultMov: 'LAST MOV',
    lastRead: 'LAST READ',
    availability: 'AVAILABILITY',
    noDelays: "NO DELAYS",
    onCircuit: 'IN CIRCUIT',
    tonLastRead: 'TON LAST READ',
    otherTerminals: 'OTHER TERMINALS',
    plannedEquipment: 'PLANNED EQUIPMENT',
    iniOps: 'STARTED OPER.',
    puestoDeAtraque: 'BERTHS',
    needFilter: 'MUST SELECT AT LEAST ONE FILTER OPTION',
    needOptionCol: 'MUST SELECT THE COLUMN TO GENERATE THE MATCHING',
    needOptionRow: 'MUST SELECT THE ROW TO GENERATE THE MATCHING',
    reverses: 'REVERSE',
    pendings: 'PENDINGS',
    freePositions: 'FREE POSITIONS',
    currentAction: 'CURRENT ACTION',
    showAll: 'SHOW ALL',
    invalidOptionPort: 'INVALID OPTION FOR THIS PORT',
    invalidPosition: 'INVALID POSITION',
    help:'HELP',
    restowPortSituation: 'RESTOW',
    abrevSLS: 'S/L/S',
    deleteBay: 'Are you sure to delete this Bay?',
    equipmentCancel: 'EQUIPMENTS CANCELATION',
    mustSelectMovType: 'MUST SELECT A TYPE OF MOVEMENT',
    mustSelectContainer: 'MUST SELECT AT LEAST ONE CONTAINER',
    listOfOnBoardContainers: 'ON BOARD CONTAINER LIST',
    restowType:'RESTOW TYPE',
    newPosition: 'NEW POSITION',
    CraneErrorTransversalView: 'CRANE ERROR IN TRANSVERSAL VIEW',
    vesselPlan: 'LETTER PLAN',
    yardsList: 'YARDS LIST',
    occupiedTeus: 'OCCUPIED TEUS',
    availableTeus: 'AVAILABLE TEUS',
    yardDetails: 'YARD DETAILS',
    yardsGeneralList: 'YARDS GENERAL LIST',
    capStatic: 'STATIC CAP.',
    capOperative: 'OPERATIVE CAPACITY',
    adminOffice: 'ADMIN OFFICE',
    adminsOffices: 'ADMINISTRATIVE OFFICE',
    nroAccess: 'ACCESS NUMBER',
    subclassification: 'SUBCLASSIFICATION',
    blocksBays: 'BLOCKS - BAYS',
    access: 'ACCESS',
    yardPlan: 'YARD PLAN',
    condition: 'CONDITION ',
    accessType: 'ACCESS TYPE',
    yardsList: 'YARDS LIST',
    yardType: 'YARD TYPE',
    inspectionLocation: 'INSPECTION LOCATION',
    bayInspection: 'BAY',
    vesselsServedGeneralList: 'VESSELS SERVED GENERAL LIST',
    mobilizedTon: 'MOBILIZED TON',
    visitsByVessel: 'VISITS BY VESSEL LIST', 
    visitList: 'VISIT LIST', 
    beginningOperationDate: 'BEGINNING OPERATION DATE', 
    endOperationDate: 'END OPERATION DATE',
    comercialAllianceWith: 'COMMERCIAL STRATEGIC ALLIANCE WITH',
    bill: 'BILL',
    billingDate: 'BILLING DATE',
    taxBase: 'TAX BASE',
    quantityOfMovilizedContainers: 'QUANTITY OF MOBILIZED CONTAINERS',
    toBeFilled: 'TO BE FILLED IN ADMON CENTRAL',
    by: 'BY',
    billing: 'BILLING',
    allianceParticipation: 'ALLIANCE PARTICIPATION',
    approvedBy: 'APPROVED BY',
    yardSetting: 'YARD SETTING',
    selectedYardSettingList: 'SELECTED YARD SETTING LIST',
    approveSetting: 'APPROVE SETTING',
    reverseSetting: 'REVERSE SETTING',
    blocks: 'BLOCKS',
    cantBlocks: 'BLOCKS QUANTITY',
    cantAreas: 'AREAS QUANTITY',
    heights: 'HEIGHT',
    yardGroup: 'YARD GROUP',
    office: 'OFFICE',
    recognition: 'CUSTOM INSPECTION',
    workshop: 'WORKSHOP',
    electricalSubstation: 'ELECTRICAL SUBSTATION',
    electricalTowers: 'ELECTRICAL TOWER',
    operationalCapacity: 'OPERATIONAL CAPACITY',
    blockWarn: 'THE BLOCK CANNOT BE ASSIGNED TO THE ZONE',
    yardContainerGeneralInfo: 'YARD CONTAINER GENERAL INFORMATION',
    daysInYard: 'DAYS IN THE YARD',
    mainFeatures: 'MAIN FEATURES',
    viewByLevel: 'VIEW BY LEVEL',
    planView: 'PLAN VIEW',
    noContainerFound: 'CONTAINER IS NOT IN YARD',
    disableConfig: '¿Are you sure to disable the configuration?',
    invalidDimensions: 'INVALID DIMENSIONS FOR THE YARD AREA',
    //DANIEL
    //-----------------------------------------------------------------------------------------------------------
    TotalMovements: "TOTAL MOVEMENTS",
    Day:'DAY',
    OperationSituationCurrent:"OPERATIONAL SITUATION CURRENT",
    OperationSituationHistorical:"OPERATIONAL SITUATION HISTORICAL",
    QuantityContainers:'QUANTITY OF CONTAINERS',
    MobilizedContainers:'MOBILIZED CONTAINERS',
    CranesTimeLine: 'CRANES TIMELINE',
    TheValueIs:'The value is: ',
    Minimize:"Minimize",
    Maximize:"Maximize",
    OperationSituationOptions:{
      OperationalSituation:"OPERATIONAL SITUATION",
      InactivityTime:"INACTIVITY TIMES",
      HistoryVesselsServed:"HISTORY OF VESSELS SERVED",
      Productivity:"PRODUCTIVITY",
      ProductivityTime:"SHIFT PRODUCTIVITY",
    },
    InactivityTimeOptions: {
      InactivityTimePerCrane: "CRANE ACTIVITY TIMES",
      InactivityTimeTheVessel: "VESSEL INACTIVITY TIMES",
      MovementsPerHoursVsNetHours: "MOVEMENTS PER HOUR VS NET HOURS",
      MovementsPerHour: "MOVEMENTS PER HOUR",
    },
    Lines: "LINES",
    TypeOfContainer: "TYPES OF CONTAINERS",
    Movements: "MOVEMENTS",
    sumaries: "SUMARIES",
    ExportAs: "EXPORT AS: ",
    Month: "MONTH",
    Yearh: "YEAR",
    selectedYear: "SELECT YEAR...",
    selectedMonth: "SELECT MONTH..",
    Description: "DESCRIPTION",
    lists: "LISTS",
    currentStatusVessel: "CURRENT STATUS OF THE VESSEL",
    preliminarycuts: "PRELIMINARY CUTS",
    reportQuery: "REPORT QUERY",
    dateRequired: "THE DATE IS REQUIRED IN ",
    dateInvalidIn: "INVALID DATE ON ",
    nameRequiredIn: "FIELD NAME REQUIRED IN ",
    dateInvalidHigherIn: "DATE CANNOT BE GREATER THAN ",
    dateInvalidLessIn: "DATE CANNOT BE LESS THAN ",
    ammounInvalidZero: "THE AMOUNT CANNOT BE LESS THAN ZERO ",
    In: " IN ",
    invalidDatesList: {
      titleDate: "The date of ",
      invalidlessThan: 'It cannot be less than ',
      invalidgreaterThan: 'It cannot be greater than ',
      invalidlessThanOrEqual: 'Must be less than or equal to ',
      invalidgreaterThanOrEqual: 'Must be greater than or equal to ',
      invalidOrEqual: 'It cannot be the same as ',
    },
    doubleClickEdit: "double click to edit",
    statusVisit: "VISIT STATUS",
    benningOperation: "BEGINNING OF OPERATIONS",
    endOperation: "END OF OPERATIONS",
    transactionHistory: "TRANSACTION HISTORY",
    shippingLine: "SHIPPING LINE",
    shippingAgent: "SHIPPING AGENT",
    shippingLineVoyage: "SHIPPING LINE VOYAGE",
    portOperator: "PORT OPERATOR",
    berth: "BERTH",
    portOrigin: "ORIGIN PORT",
    portDestiny: "DESTINY PORT",
    field: "FIELD",
    agenda: "SCHEDULE",
    times: "TIMES",
    summary_of_operations: "SUMMARY OF OPERATIONS",
    activityType: 'TYPE OF ACTIVITY',
    ock_file: "OCK FILE",
    menu_diary: {
      TERMINAL_DETAILS: "TERMINAL DETAILS",
      AGENDA_DETAILS: "SCHEDULE DETAILS",
      CRANES: "CRANES"
    },
    list_terminal: {
      n_toque: 'CALLED',
      viaje_arribo: 'V. ARRIVAL',
      viaje_zarpe: 'V. DEPARTURE ',
    },
    ships_captain: "VESSEL CAPTAIN",
    ship_security_level: 'PROTECTION LEVEL',
    name_pilot: "PILOT'S NAME",
    activitys_list: {
      call_time: 'CALL TIME',
      dock_pilot: 'DOCK PILOT',
      appointment_crew: 'APPOINTMENT OF THE CREW'
    },
    filters: "FILTERS",
    selectAll: 'SELECT ALL',
    preliminary_file: "preliminary file",
    PreliminaryDischargeFile: "PRELIMINARY DISCHARGE FILE",
    PreliminaryShipmentFile: "PRELIMINARY SHIPMENT FILE",
    TITLE_PRELIMINAR_FILE: 'PRELIMINARY FILE TITLE',
    AUTHORIZED_BY: 'AUTHORIZED BY',
    ARRIVAL_POSITION: 'ARRIVAL POSITION',
    ARRIVAL_POSITION_DATE: "ARRIVAL POSITION DATE",
    ARRIVAL_POSITION_TIME: "ARRIVAL POSITION TIME",
    DEPARTURE_POSITION: 'DEPARTURE POSITION',
    DEPARTURE_POSITION_DATE: "DEPARTURE POSITION DATE",
    DEPARTURE_POSITION_TIME: "DEPARTURE POSITION TIME",
    VOYAGE_ARRIVAL: "VOYAGE ARRIVAL",
    VOYAGE_DEPARTURE: "VOYAGE DEPARTURE",
    MOVIMIENTO: {
      SHIP_TO_SHIP: "SHIP TO SHIP",
      SHIP_LAND_TO_SHIP: "SHIP LAND TO SHIP",
      CANCEL: "CANCEL",
      observation: "OBSERVATIONS"
    },
    anular_movimiento_restow: 'Are you sure you cancel the restow movement?',
    filter_preliminar: {
      see_booth: "SEE BOTH",
      see_discrepancies: "SEE DISCREPANCIES",
      see_matches: "SEE MATCHES",
    },
    container_data: {
      not_siglas: "ACRONYMS ON THE CONTAINER DO NOT FULFILL THE VALIDATION FORMAT",
      notsameAsLoading: "Discharge port cannot be the same as loading port",
      notsameAsDischarge: "Port of loading cannot be the same as the port of discharge ",
      container_size: "The container cannot exceed the maximum size of ",
      container_weight: "The container cannot exceed the maximum weight of ",
    },
    aplicar_restow: 'APPLY / EDIT MOVEMENT RESTOW', 
    auxiliary_file: "AUXILIARY FILE",
    filename: 'FILENAME',   
    decription: "DESCRIPTION",
    value: "VALUE",
    bug_list: "BUG LIST",
    download_file_structure: 'Download file structure',
    download_instructive: 'DOWNLOAD INSTRUCTIVE',
    download_auxiliar_list_instructions: 'DOWNLOAD AUXILIARY LIST INSTRUCTIONS',
    differences: "DIFFERENCES",
    visits: "VISITS",
    visitItineraty: "VISIT / ITINERARY",
    Timeslost: "DELAY TIMES",
    detailsmobilizedteams: "DETAILS OF MOBILIZED EQUIPMENT ",
    cranesTypes: "TYPE OF CRANE",
    deviceCode: "DEVICE CODE",
    duration: "DURATION",
    timeLine: "TIMELINE",
    noDataAvailable: 'No data available',
    totalMovemnsPerDay: 'TOTAL MOVEMENTS PER DAY - SHIFT',
    seeDatails:"SEE DETAILS",
    LastUpdate: "Last update: ",
    NoLoadDistributionPlan: "No load distribution plan is available for the selected visit ",
    NoHasOperations: "OPERATIONS HAS NOT STARTED",
    DirectDischarge: "DIRECT DISCHARGE",
    Seal:"SEAL",
    Seals:"SEALS",
    Restow:"RESTOW",
    Warning:'WARNING',
    Default: 'DEFAULT',
    fileBaplie:'BAPLIE FILE',
    selectFile: "SELECT FILE",
    listings: "LISTINGS",
    preliminarList: "PRELIMINARY LIST",
    selectedFiles: "SELECTED FILES",
    resumido: "SUMMARIZED",
    confirmedContainers: "CONFIRMED CONTAINERS",
    filesTransferred: "FILE TRANSFER",
    transferredFiles: "TRANSFERRED FILES",
    totalContainers: "TOTAL CONTAINERS",
    reverse:"REVERSE",
    confirmedCOntainersDetails: "CONFIRMED CONTAINER DETAILS",
    reverseFile: 'You are sure to reverse the file transfer?',
    confirmationDate: 'CONFIRMATION DATE',
    confirmationDateAbrev: 'CONFIRMATION D.',
    selectFilesElectronics: 'SELECT ELECTRONIC FILES',
    NextPort: 'NEXT PORT',
    DeliveryPort: 'DELIVERY PORT',
    Origin: 'ORIGIN',
    assignResource: 'NEW RESOURCE',
    generalList: 'GENERAL LIST',
    accesoriesList: 'LIST OF ACCESSORIES',
    apply:"APPLY",
    highOutside: "HIGH OUTSIDE",
    lengthOutside: "OUTSIDE LENGTH",
    widthOutside: "OUTSIDE WIDTH",
    volumeOutside: "OUTSIDE VOLUME",
    highInside: "HIGH INSIDE",
    lengthInside: "INSIDE LENGTH",
    widthInside: "INSIDE WIDTH",
    volumeInside: "INSIDE VOLUME",
    emptyWeith: "EMPTY WEIGHT",
    maximumLoad: "MAXIMUM LOAD",
    maximunGrossWeight: "MAXIMUM GROSS WEIGHT",
    containerLists:{
      accesoriesList: 'CONTAINER ACCESSORIES LIST',
      clasificationsList:'CONTAINER CLASSIFICATIONS LIST',
      isoCodesList: 'CONTAINER ISO CODES LIST',
      componentsList: 'CONTAINER COMPONENTS LIST',
      conditionsList: 'CONTAINER CONDITIONS LIST',
      elementsList: 'CONTAINER PARTS LIST',
      groupsLists: 'CONTAINER GROUP LIST',
      sizeLists: 'CONTAINER SIZES LIST',
      statusLists: 'CONTAINER STATUS LIST',
      viewsLists: 'CONTAINER VIEWS LIST',
    },
    listsManual: 'MANUAL LISTS',
    history: 'HISTORY',
    typeOfFile: 'TYPE OF FILE',
    DownloadFormat: 'DOWNLOAD FORM',
    Discrepancies: 'DISCREPANCIES',
    UploadFile: 'UPLOAD FILE',
    CustomListsFormat: 'CUSTOM LIST FORMAT',
    customBroker:'CUSTOM BROKER',
    logistics:'LOGISTICS',
    NewTransportationCompany:'NEW TRANSPORTATION COMPANY',
    ScaleWeightRequired: 'IS TRUCK WEIGHING SCALE REQUIRED',  
    AssignCrane:'ASSIGN CRANE',
    stowageGans: 'STOWAGE GANS',
    fullName:'FULL NAME',
    deletedRecords: 'DELETE RECORDS',
    downloadExcel:'DOWNLOAD EXCEL',
    openErrors: 'SHOW ERRORS',
    listOfDriversTransports:'LIST OF DRIVERS BY TRANSPORTATION',
    listOfVehiclesTransports:'LIST OF VEHICLES BY TRANSPORT',
    reportMigrationMassive:'FILE MIGRATION REPORT',
    RequiredWeight:'WEIGHING REQUIRED',
    SituacionBuque:'VESSEL STATUS',
    SituacionBodega:'HOLD STATUS',
    Loadeds: 'LOADED',
    Returned: 'RETURNED',
    Dispached: 'DISPATCHED',
    VinCircuit: 'V. ON CIRCUIT',
    CTotalVoyages: 'TOTAL NUMBERS OF VOYAGES',
    TonPlanning: 'PLANNED TON',
    TonRemai: 'REMAINING TON',
    LastReading: 'LAST READING',
    DateLastRead: 'LAST READING DATE',
    CargoShipped: 'LOADED CARGO',
    GamelaByWinery: 'BIN PER HOLD',
    TonEmbargoedByShifts: 'LOADED TON PER TURNS',
    EstimatedTONperScrap: 'ESTIMATED TON PER BIN',
    Diurnal:'DIURNAL',
    Nocturnal:'NOCTURNAL',
    transportationCarrier:'CARRIER',
    StartLoard: 'START OF LOAD OPERATIONS',
    Remaing: 'REMAING',
    LoadEnd: 'END OF LOAD OPERATIONS',
    done: 'DONE',
    CurrentSituationBlVessel: 'BL-HOLD CURRENT STATUS',
    ShippedEstimatedVsPlanned: 'PLANNED VS ESTIMATED SHIPPED',
    PlannedVsCertificate: 'PLANNED VS CERTIFICATE',
    ToDischarge: 'TO DISCHARGE',
    Heavy:'WEIGHED',
    ValuesExpressedInMt: 'VALUES EXPRESSED IN TON',
    EstimedTonShipped: 'ESTIMATED TON SHIPPED',
    CurrentSituationCrane:'CURRENT SITUATION BY CRANE',
    CurrentSituationTransportation:'CURRENT TRANSPORTATION SITUATION',
    HatchMovilized:"MOBILIZED HACH COVERS",
    Certificate:'CERTIFICATE',
    CurrentSituationHold: 'CURRENT SITUATION HOLD',
    IniOps: 'STARTED OF OPERATIONS',
    FinOps: 'END OF OPERATIONS',
    HrOperando: 'HRS-OPERATING',
    PerHours: 'PER HOURS',
    PerGamela: 'PER BIN',
    TotalGamelas: 'TOTAL OF BIN',
    Loaded: 'LOADED',
    GamelaXdia:'BIN PER DAY',
    Gamela: 'BIN',
    PlannedVsCurrentStatus: 'PLANNED VS CURRENT STATUS',
    GamelaxHora: 'BIN PER HOUR',
    PerHour:'PER HOUR',
    HorasTrabajadas:'HRS. WORKED',
    HorasRetrasos:'HRS. DELAYS',
    HorasEfectivas:'EFFECTIVE HRS.',
    Annulled:'ANNULLED',
    InCircuit: 'IN CIRCUIT',
    tonEstim: 'EST TON',
    tonPlannif: 'PLND TON',
    listApk: 'APK LIST',
    nameApk: 'APK NAME',
    typeApk: 'APK TYPE',
    creationDate: 'CREATION DATE',
    LastUpdateDate: 'LAST UPDATE DATE',
    assignationGamela: 'ASSIGNATION BIN',
    HistoryAssociation:"ASSOCIATION HISTORIC",
    HistoryOrders:"ORDERS HISTORY",
    D_emission: 'ISSUE DATE',
    D_shipping: 'LOAD DATE',
    DetenerOperaciones: 'STOP OPERATIONS',
    AsignarCuadrilla: 'ASSIGN STAFF',
    Associateamela:'ASSOCIATE BIN',
    AssociationGamela:'BIN ASSOCIATION',
    PorGamela: 'PER BIN',
    TotalLoaded: 'LOADED TOTAL',
    TotalDelaysPerDay: 'TOTAL DELAYS PER DAY',
    TotalDelaysAccumulated: 'TOTAL ACCUMULATED DELAYS',
    StopOperations: 'STOP OPERATIONS',
    StopOperations2: 'DETENER OPERACIONES',
    TonxHour:'TON PER HOUR',
    Ship:'SHIP',
    OfOperation: 'OF OPERATION',
    DayOfOperations: 'DAY OF OPERATIONS',
    PerDay:'X DAY',
    LoadedTon: 'LOADED TON',
    TotalPerDay:'TOTAL PER DAY',
    tonRestante: 'REMAINING TON',
    StoppedOperation: 'STOPPED OPERATION',
    clientYard: 'CLIENT - YARD/WAREHOUSE',
    qtyWeighed: 'WEIGHED QTY',
    tonHeavy: 'TONS WEIGHED',
    qtyWithoutWeighing: 'QTY WITHOUT WEIGHING',
    tonEstimated: 'ESTIMATED TON',
    totalVoyageDone: 'TOTAL VOYAGE DONE',
    StatusOperation: 'STATUS OPERATIONS',
    searchSuccesful: 'SUCCESSFUL SEARCH',
    //HILGER
    attribute: 'ATTRIBUTE',
    correctionControl: 'CORRECTION CONTROL',
    projection: 'PROJECTION',
    exe: 'EXE',
    workHour: 'WORK HOURS',
    hour: 'HOUR',
    estimated: 'ESTIMATE',
    average: 'AVERAGE',
    equipmentDetail: 'EQUIPMENT DETAIL',
    loadPort: 'LOAD PORT',
    dischargePort: 'DISCHARGE PORT',
    generalInfo: 'GENERAL INFORMATION',
    recordInfo: 'RECORD',
    arrival: 'ARRIVAL',
    departure: 'DEPARTURE',
    canceledList: 'CANCELED LIST',
    dischargeList: 'DISCHARGE LIST',
    generate: 'GENERATE ',
    bapliesList: 'GENERATE BAPLIES LIST',
    search: 'SEARCH',
    searchContainer: 'SEARCH CONTAINER',
    docked: 'DOCKED',
    planned: 'PLANNED',
    dockAssignment: 'DOCK ASSIGNMENT',
    asignDevice: 'DEVICE ASSIGN',
    equipmentQuantity: 'EQUIPMENT QUANTITY',
    estimatedTime: 'ESTIMATED TIME',
    deviceTransfer: 'DEVICE TRANSFER',
    selectEdi: 'SELECT EDI',
    confirmation: 'CONFIRMATION',
    confirmed: 'CONFIRMED',
    tobeconfirmed: 'TO BE CONFIRMED',
    standardOrganization: 'STANDARD ORGANIZATION',
    stowageStaff: 'STOWAGE STAFF',
    mobilizedReport: 'DETAILS OF MOVILIZED REPORT',
    historyStaff: 'STAFF HISTORY',
    companyStaff: 'COMPANY STAFF',
    fileName: 'FILE NAME',
    birthday: 'BIRTHDAY',
    charge: 'CHARGE',
    inspection: 'INSPECTION',
    inspections: 'INSPECTIONS',
    inspectionType: 'INSPECTION TYPE',
    dimensions: 'DIMENSIONS',
    validateUser:'VALIDATE USER',
    validateCode: 'VALIDATE CODE',
    moment: 'MOMENT',
    transactionDate: 'TRANSACTION DATE',
    damage: 'DAMAGE',
    newDamage: 'NEW DAMAGE',
    editDamage: 'EDIT DAMAGE',
    inspectionMoment: 'INSPECTION MOMENT',
    inspectionSeals: 'INSPECTION SEALS',
    inspectionDamage: 'INSPECTION DAMAGE',
    received: 'RECEIVED',
    loadGroupAssociatedTo: 'LOAD GROUP ASSOCIATED TO',
    container: 'CONTAINER',
    width: 'WIDTH',
    weightEmpty: 'WEIGHT EMPTY',
    MaxWeight: 'MAX WEIGHT',
    maxLoad: 'MAX LOAD',
    maxGrossWeight: 'MAX GROSS WEIGHT',
    //jose angel
    itemsAndCommoditiesList: 'ITEMS AND COMMODITIES LIST',
    pdfManuals: 'PDF MANUALS',
    uploadCommodityDocuments: 'UPLOAD COMMODITY DOCUMENTS',
    handlingDocumentation: 'HANDLING DOCUMENTATION',
    openDocumentation: 'OPEN DOCUMENTATION',
    uploadHandlingDocumentation: 'UPLOAD HANDLING DOCUMENTATION',
    listOfGroupsAndImplements: 'LIST OF GROUPS AND IMPLEMENTS',
    listOfImplements: 'LIST OF IMPLEMENTS',
    Implement: 'IMPLEMENT',
    implementsPerPage: 'IMPLEMENTS PER PAGE',
    implementsGroup: 'IMPLEMENTS GROUP',
    groups: 'GROUPS',
    implements: 'IMPLEMENTS',
    implement: 'IMPLEMENT',
    gruopName: 'GROUP NAME',
    implementName: 'IMPLEMENT NAME',
    implementDescription: 'IMPLEMENT DESCRIPTION',
    unitConvertionList: 'UNIT CONVERTION LIST',
    newConvertionUnit: 'NEW CONVERTION UNIT',
    unit: 'UNIT',
    equals: 'EQUALS',
    operator: 'OPERATOR',
    units: 'UNITS',
    destination: 'DESTINATION',
    convertionUnitRegistration: 'CONVERTION UNIT REGISTRATION',
    editConversionRule: 'EDIT CONVERSION RULE',
    convertionunitType: 'CONVERTION UNIT TYPE',
    typeOfUnitToConvert: 'TYPE OF UNIT TO CONVERT',
    unitOfOrigin: 'UNIT OF ORIGIN',
    unitOfDestination: 'UNIT DESTINATION',
    mathematicalOperation: 'MATHEMATICAL OPERATION',
    packagingList: 'PACKAGING LIST',
    groupAndType: 'GROUP AND TYPE',
    imdg: 'IMDG',
    imdgClass: 'IMDG CLASS',
    newImdg: 'NEW IMDG',
    imdgCode: 'IMDG CODE',
    imdgDivision: 'IMDG DIVISION',
    classCode: 'CLASS CODE',
    imdgList: 'IMDG LIST',
    imdgClassName: 'IMDG CLASS NAME',
    imdgClassAcronym: 'IMDG CLASS ACRONYM',
    classAcronym: 'CLASS ACRONYM',
    imdgDivisionName: 'IMDG DIVISION NAME',
    measurementUnitsList: 'MEASUREMENT UNITS LIST',
    measurementUnitType: 'MEASUREMENT UNIT TYPE',
    meaurenmentUnitRegistration: 'MEASUREMENT UNIT REGISTRATION',
    measurementUnitAcronym: 'MEASUREMENT UNIT ACRONYM',
    measurementUnitName: 'MEASUREMENT UNIT NAME',
    measurementUnitTypeName: 'MEASUREMENT UNIT TYPE NAME',
    radioactiveMaterials: 'RADIOACTIVE MATERIALS',
    multiplication:'MULTIPLICATION',
    editImplementGroup:'EDIT IMPLEMENT GROUP',
    conversionRule:'CONVERSION RULE',
    portActivityName:'PORT ACTIVITY NAME',
    municipalityName:'MUNICIPALITY NAME',
    countryName:'COUNTRY NAME',
    alpha2Code:'ALPHA 2 CODE',
    alpha3Code:'ALPHA 3 CODE',
    countryNumericCode:'COUNTRY NUMERIC CODE',
    flag:'FLAG',
    parishName:'PARISH NAME',
    everybody:'EVERYBODY',
    moduleGroup:'MODULE GROUP',
    viewHierarchy:'VIEW HIERARCHY',
    seeRolesAssociatedWithTheModule:'SEE ROLES ASSOCIATED WITH THE MODULE',
    dashLogin:'DASH LOGIN',
    groupModuleList:'GROUP MODULE LIST',
    father:'FATHER',
    newModuloRegistrarion:'NEW MODULE REGISTER', 
    dependencies:'DEPENDENCIES',
    gpmoduloName:'GPMODULO NAME',
    apiRoutes:'API ROUTES',
    structure:'STRUCTURE', 
    fileDirectory:'FILE DIRECTORY', 
    loadBaplie:'LOAD BAPLIE',
    dischargeBaplie: 'DISCHARGE BAPLIE', 
    //Nubia
    grossProductivityWithLostTime: "'GROSS' PRODUCTIVITY WITH LOST TIME",
    berthProductivityWithLostTime: "'BERTH' PRODUCTIVITY WITH LOST TIME",
    netProductivityWithoutLostTime: "'NET' PRODUCTIVITY WITHOUT LOST TIME",
    beginningDischarge: 'BEGINNING DISCHARGE',
    finishDischarge: 'FINISH DISCHARGE',
    beginningLoad: 'BEGINNING LOAD',
    finishLoad: 'FINISH LOAD',
    twinsBoxes: 'TWINS BOXES',
    hatchCovers: 'HATCH COVERS',
    humanResources: 'RRHH',
    forklift: 'FORKLIFT',
    liftingEquipment: 'LIFTING EQUIPMENT',
    security: 'SECURITY',
    wincher: 'WINCHER',
    receivingWarehouse: 'RECEIVING WAREHOUSE',
    totalTimeOfOperations: 'TOTAL TIME OF OPERATIONS',
    netTimeOfOperations: 'NET TIME OF OPERATIONS',
    hours: 'HOURS',
    timeStopped: 'TIME STOPPED',
    dateTimeArrival: 'DATE / TIME ARRIVAL',
    dateTimeBerthDate: 'DATE / TIME BERTH',
    dateTimeBeginningOperations: 'DATE / TIME BEGINNING OPERATIONS',
    dateTimeEndOperations: 'DATE / TIME END OPERATIONS',
    dateTimeDeparture: 'DATE / TIME DEPARTURE',
    assignedBerthNro: 'ASSIGNED BERTH NO.',
    errorsSameDateTime: "DATE 'FROM' MUST NOT BE EQUAL THAN DATE 'TO', PLEASE CHECK",
    errorsFromTimeToTime: "TIME 'FROM' MUST NOT BE GREATER OR EQUAL THAN TIME 'TO', PLEASE CHECK",
    errorsFromTimeAta: "TIME 'FROM', MUST NOT BE LESS THAN 'ATA/ETA', PLEASE CHECK",
    errorsFromTimeAtd: "TIME 'FROM', MUST NOT BE GREATER THAN 'ATD', PLEASE CHECK",
    errorsFromCurrentTime: "TIME 'FROM', MUST NOT BE GREATER THAN THE 'CURRENT TIME', PLEASE CHECK",
    errorsToTimeAta: "TIME 'TO', MUST NOT BE LESS THAN 'ATA/ETA', PLEASE CHECK",
    errorsToTimeAtd: "TIME 'TO', MUST NOT BE GREATER THAN 'ATD', PLEASE CHECK",
    errorsToCurrentTime: "TIME 'TO', MUST NOT BE GREATER THAN THE 'CURRENT TIME', PLEASE CHECK",
    errorsFromDateAta: "DATE 'FROM', MUST NOT BE LESS THAN 'ATA/ETA', PLEASE CHECK",
    errorsFromDateAtd: "DATE 'FROM', MUST NOT BE GREATER THAN 'ATD', PLEASE CHECK",
    errorsFromCurrentDate: "DATE 'FROM', MUST NOT BE GREATER THAN THE 'CURRENT DATE', PLEASE CHECK",
    errorsToDateAta: "DATE 'TO', MUST NOT BE LESS THAN 'ATA/ETA', PLEASE CHECK",
    errorsToDateAtd: "DATE 'TO', MUST NOT BE GREATER THAN 'ATD', PLEASE CHECK",
    errorsToCurrentDate: "DATE 'TO', MUST NOT BE GREATER THAN THE 'CURRENT DATE', PLEASE CHEC'",
    errorsFromTimeOrderBeginning: "TIME 'FROM', MUST NOT BE LESS THAN THE 'START TIME OF THE ORDER', PLEASE CHECK",
    errorsFromTimeOrderFinish: "TIME 'FROM', MUST NOT BE GREATER THAN THE 'FINISH TIME OF THE ORDER', PLEASE CHECK",
    errorsToTimeOrderBeginning: "TIME 'TO', MUST NOT BE LESS THAN THE 'START TIME OF THE ORDER', PLEASE CHECK",
    errorsToTimeOrderFinish: "TIME 'TO', MUST NOT BE GREATER THAN THE 'FINISH TIME OF THE ORDER', PLEASE CHECK",
    errorsFromDateOrderBeginning: "DATE 'FROM', MUST NOT BE LESS THAN THE 'START DATE OF THE ORDER', PLEASE CHECK",
    errorsFromDateOrderFinish: "DATE 'FROM', MUST NOT BE GREATER THAN THE 'FINISH DATE OF THE ORDER', PLEASE CHECK",
    errorsToDateOrderBeginning: "DATE 'TO', MUST NOT BE LESS THAN THE 'START DATE OF THE ORDER', PLEASE CHECK",
    errorsToDateOrderFinish: "DATE 'TO', MUST NOT BE GREATER THAN THE 'FINISH DATE OF THE ORDER', PLEASE CHECK",
    weighingReport: 'WEIGHING REPORT',
    detailed: 'DETAILED',
    daysInYard: 'DAYS IN YARD',
    returnType: 'RETURN TYPE',
    returnedBins: 'RETURNED BINS',
    transportVehicle: 'TRANSPORT-VEHICLE',
    detentionCrane: 'DETENTION CRANE',
    associationGamelaList:'BIN ASSOCIATION LIST',
    incidenceOpen: 'INCIDENCE OPEN',
    voyages: 'VOYAGES',
    branchByRoles: 'BRANCH BY ROLES',
    errorsDateTimePleaseCheck: 'Errors in Date Time, please check.',
    netoTotal: 'TOTAL NETO',
    grossTotal: 'TOTAL GROSS',
    tareTotal: 'TOTAL TARE',
    weightTotal: 'TOTAL WEIGHT',
    voyagesTotal: 'TOTAL VOYAGES',
    statistics: 'STATISTICS',
    dispacher: 'DISPACHER',
    dispatch: 'DISPATCH',
    dispatchDate: 'DISPATCH DATE',
    weighingDetailReport: 'WEIGHING DETAIL REPORT',
    binWeighing: 'BIN WEIGHING',
    binWeighingPerYard: 'BIN WEIGHING PER YARD',
    weighingReturnedBin: 'WEIGHING RETURNED BIN',
    reportType: 'REPORT TYPE',
    timesSheetReport: 'TIMES SHEET REPORT',
    timesSheet: 'TIMES SHEET',
    inDateFrom: 'IN DATE FROM',
    inDateTo: 'IN DATE TO',
    vesselsServedIn:"VESSELS SERVED IN",
    vesselsServed:"VESSELS SERVED",
    strategicAlliance: 'STRATEGIC ALLIANCE',
    mobilizedFull: 'MOBILIZED FULL',
    mobilizedEmpty: 'MOBILIZED EMPTY',
    totals: 'TOTALS',
    total: 'TOTAL',
    dischargeFull: 'DISCHARGE FULL',
    dischargeEmpty: 'DISCHARGE EMPTY',
    loadFull: 'LOAD FULL',
    loadEmpty: 'LOAD EMPTY',
    movilizedReturned: 'MOVILIZED RETURN',
    finalOperationsReport: 'FINAL OPERATIONS REPORT',
    requiredGamelaChange: 'REQUIRED BIN CHANGE',
    requiredVehicleChange: 'REQUIRED VEHICLE CHANGE',
    yardPosition: 'YARD POSITION',
    yardBl: 'BL YARD',
    declaredTons: 'TONS DECLARED',
    orderAssociation: 'ORDER ASSOCIATION',
    orderListPerIncidenceType: 'ORDER LIST PER INCIDENCE TYPE',
    incidenceList: 'INCIDENCE LIST',
    errorsStartDateTimeOrderPleaseCheck: 'TIME MUST BE GREATER THAN THE ORDER TIME, PLEASE CHECK',
    net: 'NET',
    lost: 'LOST',
    bl: 'BL',
    orderDate: 'ORDER DATE',
    delayTime: 'DELAY TIME',
    orderQuantity: 'ORDER QUANTITY',
    asignResource: 'RESOURCE ASSIGN',
    enterEmployeeInfo: 'ENTER EMPLOYEE INFO',
    employeeInfo: 'EMPLOYEE INFO',
    rotate: 'ROTATE',
    version: 'VERSION',
    review: 'REVIEW',
    effectiveTime: 'EFFECTIVE TIME',
    incidenceDate: 'INCIDENCE DATE',
    yardConsignee: 'YARD CONSIGNEE',
    invalidGreaterThan: 'Must be greater than',
    sspNumber: 'SSP NUMBER',
    sspYear: 'SSP YEAR',
    onePortCompanyBranch: 'ONE OF THE PORTS MUST BE A COMPANY BRANCH',
    notsameAsOrigin: "Destination port cannot be the same as the origin port",
    registerMachineFirst: 'FIRST YOU MUST REGISTER THE DATA OF THE MACHINE', 
    idleTime: 'IDLE TIME',
    transactionsTablets: 'TRANSACTIONS - TABLETS',
    tdr: 'TDR',
    bayView: 'BAY VIEW',
    fullCargoPlan: 'FULL CARGO PLAN',
    yardClient: 'YARD - CLIENT',
    vehicleBin: 'VEHICLE - BIN',
    detail: 'DETAIL',
    yardClientSummary: 'YARD - CLIENT SUMMARY',
    vehicleBinSummary: 'VEHICLE - BIN SUMMARY',
    tonsWeighed: 'TONS WEIGHED',
    estimatedTons: 'ESTIMATED TONS',
    gamelasQuantity: 'BIN QUANTITY',
    orders: 'ORDERS',
    transportSummary: 'TRANSPORT SUMMARY',
    hatchCoverLocation: 'HATCH COVER LOCATION',
    inspectionDate: 'INSPECTION DATE',
    canceledDocument: 'CANCELED',
    generateOrder: "GENERATE ORDER", 
    dateFrom: 'DATE FROM',
    dateTo: 'DATE TO',
    fullWeighing: 'FULL WEIGHING',
    emptyWeighing: 'EMPTY WEIGHING',
    logisticTimeLine: 'LOGISTIC TIMELINE',
    verifyDriverId: "VERIFY DRIVER ID",
    verifyLicensePlate: "VERIFY LICENCE PLATE",
    transport: 'CARRIER',
    incidenceType: 'INCIDENCE TYPE',
    noOrder: 'ORDER NO.',
    emissionDate: 'EMISSION DATE',
    shippingDate: 'SHIPPING DATE',
    lapse: 'SHIFT',
    totalTime: 'TOTAL TIME',
    checker: 'CHECKER',
    lastDate: 'LAST DATE',
    currentCircuit: 'CURRENT CIRCUIT',
    draftInspections: 'DRAFT INSPECTIONS',
    portPlan: 'PORT PLAN',
    ordersByCarrier: 'ORDERS BY CARRIER',
    ordersByDriver: 'ORDERS BY DRIVER',
    ordersByVehicle: 'ORDERS BY VEHICLE',
    ordersByScrapContainer: 'ORDERS BY BIN',
    ordersByYard: 'ORDERS BY YARD',
    dailyReport: 'DAILY REPORT',
    finalReport: 'FINAL REPORT',
    operationalReport: 'OPERATIONAL REPORT',
    tablets: 'TABLETS',
    ordersList: 'ORDERS LIST',
    incidence: 'INCIDENCE',
    incidences: 'INCIDENCE',
    shipmentOrders: 'SHIPMENT ORDERS',
    shipmentOrder: 'SHIPMENT ORDER',
    minimum: 'MINIMUM',
    operationsSummary: 'VESSEL ACTIVITY RECAP',
    delaysByVessel: 'DELAYS BY VESSEL',
    delaysByOperator: 'DELAYS BY OPERATOR',
    delaysPerPort: 'DELAYS PER PORT',
    providers: 'PROVIDERS',
    ice: 'ICE',
    water: 'WATER',
    food: 'FOOD',
    item: 'ITEM',
    mobilizationOfShipHatch: 'MOBILIZATION OF SHIP HATCH COVER',
    mobilizationOfTwinboxes: 'MOBILIZATION OF TWINBOXES', 
    supplementForSealingEmptyContainers:'SUPPLEMENT FOR SEALING EMPTY CONTAINERS (MINIMUM$50.00)',
    seals: 'SEALS',
    sealingEmptyContainers:'SEALING EMPTY CONTAINERS',
    shippingContainers: 'SHIPPING CONTAINERS',
    containersFull: 'FULL',
    containersEmpty: 'EMPTY',
    unloadContainers: 'UNLOAD CONTAINERS', 
    metricTonnageAcronym: '(TON)',
    ballast: 'BALLAST',
    fuelOil: 'FUEL OIL',
    dieselOil: 'DIESEL OIL',
    constant: 'CONSTANT',
    freshWater: 'FRESH WATER',
    errorsCurrentDateTimePleaseCheck: 'TIME MUST NOT BE GREATER THAN THE CURRENT TIME, PLEASE CHECK',
    errorsStartDateTimePleaseCheck: 'TIME MUST NOT BE LESS THAN ATA/ETA, PLEASE CHECK',
    errorsHourDateTimePleaseCheck: 'END TIME MUST NOT BE GREATER OR EQUAL THAN START TIME, PLEASE CHECK',
    transactionReverseList: 'TRANSACTION REVERSE LIST',
    CurrentPosition: 'CURRENT POSITION',
    previousPosition: 'PREVIOUS POSITION',
    transactionReversals: 'TRANSACTION REVERSALS',
    damageOption: 'DAMAGE',
    searchForContainerToContinue: 'YOU MUST SEARCH THE CONTAINER FIRST TO BE ABLE TO CONTINUE WITH THE REGISTRATION',
    beginningTurn: 'BEGINNING OF TURN',
    finalTurn: 'FINAL TURN',
    netTime: 'NET TIME',
    timeOperations: 'TIME OPERATIONS',
    beginningHour: 'BEGINNING OF HOUR',
    finalHour: 'END HOUR',
    inactivityTime: 'INACTIVITY TIME',
    delayTimesWithoutMovements: 'DELAY TIMES WITHOUT MOVEMENTS', 
    registerDate: 'REGISTER DATE',
    editWorkShiftPerVesselType: 'EDIT WORK SHIFT PER VESSEL TYPE',
    newWorkShiftPerVesselType: 'NEW WORK SHIFT PER VESSEL TYPE',
    WorkShiftsPerVesselType: 'WORK SHIFTS BY VESSEL TYPE',
    WorkShiftPerVesselType: 'WORK SHIFT PER VESSEL TYPE',
    turns: 'TURNS',
    stern: 'STERN',
    bow: 'BOW',
    gross: 'GROSS',
    stowageRoles: 'STOWAGE ROLES',
    verifyContainer: "VERIFY CONTAINER ACRONYMS",
    containerAcronyms: 'CONTAINER ACRONYMS',
    deleteImage: 'Are you sure to delete the image?',
    other: 'OTHER',
    CULMINADO: 'COMPLETED',
    correlative: 'CORRELATIVE',
    staffOperator: 'STAFF OPERATOR',
    staffSupervisor: 'STAFF SUPERVISOR',
    supervisor: 'SUPERVISOR',
    uploadDocuments: 'UPLOAD DOCUMENTS',
    clearFilter: 'CLEAR FILTER',
    requiresAtLeastOneSealInspection: 'REQUIRES AT LEAST ONE SEAL INSPECTION',
    withoutSeal: 'WITHOUT SEAL',
    time: 'TIME',
    errorList: 'ERROR LIST',
    driverId: 'DRIVER ID',
    driverName: 'DRIVER NAME',
    driverLicenseDateFormat: 'DD-MM-YYYY',
    vehicleClasificationName: 'NAME CLASSIFICATION OF VEHICLE',
    driversAndVehicleList: 'DRIVERS AND VEHICLE LIST',
    driver: 'DRIVER',
    drivers: 'DRIVERS',
    driverLicense: 'DRIVER LICENSE',
    licenseExpirationDate: 'EXPIRATION DATE',
    vehicle: 'VEHICLE',
    tpVehicle: 'VEHICLE TYPE',
    tpVehicleName: 'VEHICLE TYPE NAME',
    vehiclePlate: 'VEHICLE PLATE',
    vehicleClasification: 'VEHICLE CLASIFICATION',
    vehicleClasific: 'VEHICLE CLASIFICATION',
    vehicleType: 'VEHICLE TYPE',
    clasificationName: 'CLASIFICATION NAME',
    listMachine: 'MACHINE LIST',
    registerMachine: 'MACHINE REGISTER',
    registerPhotoMachine: 'MACHINE PHOTO REGISTER',
    machineTypeRelationShip: 'MACHINE TYPE RELATION SHIP',
    serialUnique: 'UNIQUE SERIAL',
    nameIdDriver: 'NAME OR ID DRIVER',
    tableReset: 'TABLE RESET',
    aggregate: 'ADD',
    machineTypeName: 'MACHINE TYPE NAME',
    selectOption: 'SELECT AN OPTION',
    icon: 'ICON',
    typeMachine: 'MACHINE TYPE',
    containers: 'CONTAINERS',
    associatedEquipment: 'ASSOCIATED EQUIPMENT',
    machineAssociation: 'MACHINE ASSOCIATION',
    bulk: 'BULK',
    generalCargo: 'GENERAL CARGO',
    clientsActivitiesList: 'CLIENTS AND ACTIVITIES LIST',
    activities: 'ACTIVITIES',
    transactions: 'TRANSACTIONS',
    rifNumber: 'RIF NUMBER',
    transactionsClient: 'TRANSACTIONS GENERATED BY CLIENT',
    motive: 'MOTIVE',
    statusDate:"STATUS DATE",
    previousStatus:"PREVIOUS STATUS",
    newStatus:"NEW STATUS",
    clientActivities: 'CLIENT ACTIVITIES',
    dataTable: 'DATA TABLE',
    updateActivities: 'UPDATE ACTIVITIES',
    grabar: 'SAVE',
    clientsByActivity: 'CLIENTS BY ACTIVITY',
    plate: 'PLATE',
    errorSelectActivities: 'Please select the Activities.',
    shippingLineList: 'SHIPPING LINES LIST',
    assignAcronym: 'ASSING ACRONYM',
    assignShippingLineRepresentative: 'ASSIGN SHIPPING LINE REPRESENTATIVE',
    requiresSuplier: 'REQUIRES SUPLIER',
    completedSuccessfully: 'YOUR PROCESS HAS BEEN COMPLETED SUCCESSFULLY',
    unexpectedError: 'UNEXPECTED ERROR HAS OCURRED',
    lineRepresentative: 'LINE REPRESENTATIVE',
    inactivateRepresentative: 'INACTIVATE REPRESENTATIVE',
    activateRepresentative: 'ACTIVATE REPRESENTATIVE',
    shippingLineAbbreviations: 'SHIPPING LINE ABBREVIATIONS',
    abbreviation: 'ABBREVIATION',
    newShippingLine: 'SHIPPING LINE',
    activityName: 'ACTIVITY NAME',
    clientRepresentativeName: 'CLIENT REPRESENTATIVE NAME',
    clientAddress: 'CLIENT ADDRESS',
    clientName: 'CLIENT NAME',
    newShippingLineName: 'SHIPPING LINE NAME',
    newShippingLineAddress: 'SHIPPING LINE ADDRESS',
    newShippingLineAbbreviation: 'SHIPPING LINE ABBREVIATION',
    inactivateActivity: 'INACTIVATE ACTIVITY',
    activateActivity: 'ACTIVATE ACTIVITY',
    inactivateRole: 'INACTIVAR ROL',
    activateRole: 'ACTIVAR ROL',
    applySigetMobile: 'APPLY SIGET-MOBILE',
    lostTimeCode: 'DELAY TIME CODE',
    devices: 'DEVICES',
    applyTo: 'APPLY TO',
    noSearchData: 'NO SEARCH DATA',
    documentQuestion: 'REQUIRES A DOCUMENT',
    addFileQuestion: 'ARE YOU SURE TO UPLOAD THE FILE',
    carrier: 'CARRIER',
    containerCode: 'CONTAINER CODE',
    transhipmentPort: 'TRANSHIPMENT PORT',
    certificateVGM: 'VGM CERTIFICATE',
    class: 'CLASS',
    length: 'Length',
    columnConfig: 'COLUMN CONFIGURATION',
    portCodes: 'PORT CODES',   
    hideTableRoles: 'HIDE TABLE OF SELECTED ROLES',
    viewTableRoles: 'VIEW TABLE OF SELECTED ROLES',
    delayTdrCode: 'DELAYS TDR CODE',
    delayCustomCode: 'DELAYS CUSTOM CODE',
    customCode: 'CUSTOM CODE',
    chronometer: 'CHRONOMETER',
    dateRange: 'DATE RANGE',
    app: 'APP',
    errorsDatePleaseCheck: 'Errors in Date, please check.',
    errorsHourPleaseCheck: 'Errors in Hour, please check.',
    fin: 'END',
    inicio: 'START',
    twinsBox: 'TWINS BOX',
    configurations: 'SETTINGS',
    delayResponsible: 'DELAY RESPONSIBLE',
    others: 'OTHERS',
    codesDocuments: 'CODES PER DOCUMENTS',
    vesselDocuments: 'VESSEL DOCUMENTS',
    errorsIdleTime: 'SELECTED VESSEL DOESNT HAVE A DELAY TIME REGISTERED',
    errorsUnitSelected: 'THE TYPE OF MEASUREMENT UNIT HAS BEEN PREVIOUSLY SELECTED',
    geolocation: 'GEOLOCATION',  
    color: 'COLOR',
    timeCode: 'TIME CODE',
    equipmentTotal: 'EQUIPMENT TOTAL',
    timeReport: 'TIME REPORT',
    ALL: 'ALL',
    dischargePortVisit: 'DISCHARGE PORT',
    generalContainers: 'CONTAINERS (GENERAL)', 
    movementsRestow: 'RESTOW', 
    shipments: 'LOAD',
    canceled: 'CANCELED',
    discharges: 'DISCHARGE',
    vgm: 'VGM',
    vgmDetail: 'VGM DETAIL', 
    oversizedContainers: 'OOG CARGO',
    reeferContainers: 'REEFER CONTAINERS',
    imdgContainers: 'DANGEROUS CARGO',
    containersByTypeStatus: 'TYPE-STATUS CONTAINERS',
    containersByType: 'TYPE CONTAINERS',
    containersByPortLine: 'PORT-LINE CONTAINERS', 
    typeOfLoadPerCrane: 'TYPE OF LOAD BY CRANE',
    listOfPlannedGeneralContainers: 'CONTAINER LIST',
    listOfPlannedImdgContainers: 'DANGEROUS CARGO LIST',
    listOfPlannedMovementsRestow: 'RESTOW LIST',
    listOfPlannedReeferContainers: 'REEFER LIST',
    listOfPlannedShipments: 'LOAD LIST',
    listOfPlannedCanceled: 'CANCELED LIST',
    listOfPlannedDischarges: 'DISCHARGE LIST',
    listOfPlannedVgmDetail: 'VGM DETAIL',
    listOfPlannedOversizedContainers: 'OOG CARGO LIST',
    summaryOfPlannedContainersByTypeStatus: 'CONTAINER TYPE SUMMARY',
    summaryOfPlannedContainersByType: 'CARGO SUMMARY',
    summaryOfPlannedContainersByPortLine: 'LOAD PORT SUMMARY BY OPERATOR',
    summaryOfPlannedImdgContainers: 'DANGEROUS CARGO SUMMARY',
    listOfGeneralContainers: 'CONTAINER LIST (GENERAL)',
    listOfImdgContainers: 'DANGEROUS CARGO LIST',
    listOfMovementsRestow: 'RESTOW LIST',
    listOfReeferContainers: 'REEFER LIST',
    listOfShipments: 'LOAD LIST',
    listOfCanceled: 'CANCELED LIST',
    listOfDischarges: 'DISCHARGE LIST',
    listOfOversizedContainers: 'OOG CARGO LIST',
    summaryOfContainersByTypeStatus: 'CONTAINER TYPE SUMMARY',
    summaryOfContainersByType: 'CARGO SUMMARY',
    summaryOfContainersByPortLine: 'LOAD PORT SUMMARY BY OPERATOR',
    summaryOfImdgContainers: 'DANGEROUS CARGO SUMMARY',
    summaryCraneLoadType: 'TYPE OF LOAD BY CRANE SUMMARY',
    transactionReport: 'TRANSACTIONS REPORT',
    noRecordsAvailbleReport: 'NO RECORDS AVAILABLE TO GENERATE THE REPORT',
    printed: 'PRINTED',
    reportSuccessfully: 'SUCCESSFULLY GENERATED REPORT',
    reportError: 'ERROR WHEN GENERATING THE REPORT',
    discountedTdr: 'WITH DISCOUNTS',
    pageReport: 'Page',
    ofReport: 'of',
    delay: 'DELAY',
    timeCodeDetails: 'TIME CODE DETAILS',
    applyTdr: 'APPLIES TO TDR',
    currentTime: 'CURRENT TIME',
    serial: 'SERIAL',
    serials: 'SERIALS',
    berthVisit: 'BERTH',
    positionContainer: 'POSITION',
    isoCodes: 'ISO CODES',
    equivalent: 'EQUIVALENT',
    image: 'IMAGE',
    images: 'IMAGES',
    selectedImage: 'SELECTED IMAGE',
    toPage: 'TO',
    records: 'RECORDS',
    discharge:'DISCHARGE',
    modality: 'MODALITY',
    height: 'HEIGHT',
    actualConditions: 'ACTUAL CONDITIONS',
    //Angel
    resultEqualValue: 'RESULT = VALUE ',
    locationName: 'LOCATION NAME',
    ID: "ID",
    password:"PASSWORD",
    userIdentificationInfo:"USER IDENTIFICATION INFO",
    invalidContainerCode: "CODIGO DE CONTENEDOR INVALIDO",
    checkDigit:"CHECK-DIGIT",
    asignUserRole: 'ASIGN USER ROLE',
    rolesAssigned: 'ROLES ASIGNED',
    rolesToBeAssigned: 'ROLES TO BE ASIGNED',
    selectedRoles: 'SELECTED ROLE(S)',
    transactionHourDate: "TRANSACTION HOUR/DATE", 
    generateReport: "GENERATE REPORT", 
    holiday: 'HOLIDAY',
    holidayDay: 'HOLIDAY',
    holidayDayName: 'HOLIDAY DAY NAME',
    holidayDate: 'HOLIDAY DATE',
    holidayType: 'HOLIDAY TYPE',
    holidayTypeName: 'HOLIDAY TYPE NAME',
    calendary: 'CALENDAR',
    colorIdentification: 'color Identification',
    standard:'STANDARD',
    standardDocumentation:'STANDARD DOCUMENTATION',
    files: 'FILES',
    regulatoryBody: "REGULATORY BODY",
    path: 'PATH',
    //MARIA
    Alias: 'ALIAS NAME',
    registeredPreviouslyCircuit: 'REGISTERED PREVIOUSLY CIRCUIT',
    modalityName: 'MODALITY NAME',
    CircuitAssociation: 'CIRCUIT ASSOCIATION',
    ModalityAssociation: 'ASSOCIATION MODALITY',
    Up: 'UP',
    Down: 'DOWN',
    IdentificationClient: 'CLIENT IDENTIFICATION',
    ClientType: 'CLIENT TYPE',
    OperationType: 'OPERATION TYPE',
    representativeName: 'REPRESENTATIVE CLIENT NAME',
    CancellationReasons: 'CANCELLATION REASONS',
    CancellationReason: 'CANCELLATION REASON',
    Cancellation: 'CANCELLATION',
    CancellationReasonName: 'CANCELLATION REASON NAME',
    Vehicles: 'VEHICLES',
    Bin: 'BINS', 
    listDriverVehicleBin: 'DRIVER, VEHICLE AND BIN LIST ',
    entryOrders: 'ENTRY ORDERS',
    shipmentOrders: 'SHIPMENT ORDERS',
    seeOrders: 'SEE ORDERS',
    Activity: 'ACTIVITY',
    AssociationActivityClient: 'ACTIVITY - CLIENTS',
    Association: 'ASSOCIATION',
    //JAVIER
    BolipuertoAct: 'BOLIPUERTO ACT',
    BlHouse: 'BL HOUSE',
    GoToBlMaster: 'GO TO BL MASTER',
    YardYard: 'PATIO',
    ReceivedId: 'RECEIVED ID',
    ReceivedBy: 'RECEIVED BY',
    OriginYard: 'ORIGIN YARD',
    DaysInYardWarehouse: 'DAYS IN YARD / WAREHOUSE',
    YardWarehouseOrigin: 'LOCATION ORIGIN',
    YardWarehouse: 'YARD / WAREHOUSE',
    PackagingOrigin: 'PACKAGING ORIGIN',
    SerialOrigin: 'SERIAL ORIGIN',
    WeightOrigin: 'WEIGHT ORIGIN',
    AbbreviatedPlanned: 'PLAN.',
    AbbreviatedReception: 'RECEP.',
    AbbreviatedDispatch: 'DISP.',
    UserCertifies: 'USER CERTIFIES',
    CertificateDate: 'CERTIFICATE DATE',
    CertificateNro: 'CERTIFICATE NO.',
    CertificateEntry: 'CERTIFICATE OF ENTRY',
    CertificatesEntry: 'CERTIFICATES OF ENTRY',
    statusCertificate: 'DOCUMENT CODE STATUS',
    daysInYard:"DAYS IN YARD",
    Certificate: 'DOCUMENT CODE',
    ApplySerializatiom: 'APPLY SERIALIZATION',
    PackagingIsFullySerialized: 'THE PACKAGING IS FULLY SERIALIZED',
    Shopper: 'SHOPPER',
    clientConsignee: 'CONSIGNEE CLIENT',
    PackagingIsSerialized: 'THE PACKAGING IS SERIALIZED',
    PackagingSuccessfullySaved: 'PACKAGING SUCCESSFULLY SAVED',
    SerializationByPackaging: 'SERIALIZATION BY PACKAGING',
    SerializationSavedSuccessfully: 'SERIALIZATION SAVED SUCCESSFULLY',
    NotSerializedQuantity: 'QUANTITY CANNOT BE LESS THAN SERIALIZED QUANTITY',
    NotSerializedWeight: 'WEIGHT CANNOT BE LESS THAN SERIALIZED WEIGHT',
    NotSerializedVolume: 'VOLUME CANNOT BE LESS THAN SERIALIZED VOLUME',
    PackagingQuantity: 'PACKAGING / QUANTITY',
    Serialized: 'SERIALIZED',
    packagingNoySerializedQuantity:"PACKAGING QUANTITY DOES NOT MATCH SERIALIZED QUANTITY",
    applySerialization :"APPLY SERIALIZATION",
    Serialization: 'SERIALIZATION',
    CurrentSituation: 'CURRENT SITUATION',
    DaysInPort: 'DAYS IN PORT',
    house: 'HOUSE',
    FirstEvent: 'FIRST EVENT',
    FirstEventDate: 'FIRST EVENT DATE',
    LastEvent: 'LAST EVENT',
    LastEventDate: 'LAST EVENT DATE',
    BlNro: 'BL NO.',
    BlQuery: 'BL QUERY',
    TotalCargo: 'TOTAL CARGO',
    MOVILIZADO: 'RESTOW',
    CertifiedQuantity: 'CERTIFIED QUANTITY',
    CertifiedReceived: 'RECEIVED QUANTITY',
    BreakBulk: 'BREAK BULK',
    ToIdentify: 'TO IDENTIFY',
    SituationPackaging: 'SITUATION PER PACKAGING',
    FileAlert: 'IT IS RECOMMENDED TO THE INSTRUCTIONS BEFORE FILLING OUT THE FILE',
    Identified: 'IDENTIFIED',
    VisitSelected: 'A VISIT MUST BE SELECTED',
    LastLoad: 'LAST LOAD',
    TonLoaded: 'LOADED TON',
    quantityExecuted: 'QUANTITY EXECUTED',
    AssignAll: 'ASIGNAR TODO',
    SumOfWeightPerDetail: 'SUM OF WEIGHT PER DETAIL',
    SumOfVolumePerDetail: 'SUM OF VOLUME PER DETAIL',
    SumOfQuantityPerDetail: 'SUM OF QUANTITY PER DETAIL',
    DistributionInDeck: 'DISTRIBUTION IN DECK',
    DistributionInHold: 'DISTRIBUTION IN HOLD',
    DistributionInDeckHold: 'DISTRIBUTION IN DECK / HOLD',
    BlContainerList: 'BL CONTAINER LIST',
    CustomInspetion: "CUSTOM INSPECTION",
    AssignmentToDeck: 'ASSIGNMENT TO DECK',
    PlannedYard: 'PLANNED YARD',
    ItemPreviouslyRegistered: 'ITEM PREVIOUSLY REGISTERED',
    ApplyPackaging: 'APPLY PACKAGING',
    TotalVolume: 'TOTAL VOLUME',
    QuantityAvailabilityPerDetail: 'QUANTITY AVAILABILITY PER DETAIL',
    QuantityAvailabilityPerPackaging: 'QUANTITY AVAILABILITY PER PACKAGING',
    ModalityOfOperations: 'MODALITY OF OPERATIONS',
    AvailabilityByDetail: 'AVAILABILITY BY DETAIL',
    AvailabilityByPackaging: 'AVAILABILITY BY PACKAGING',
    OversizedEquipment: 'OVERSIZED EQUIPMENT',
    DangerousGood: 'DANGEROUS GOOD',
    ContainerList: 'CONTAINER LIST',
    TransferredFile: "TRANSFERRED FILE",
    ExternalPackaging: 'EXTERNAL PACKAGING',
    InternalPackaging: 'INTERNAL PACKAGING',
    DateOfDischarge: 'DATE OF DISCHARGE',
    WasLoadedSuccessfully: 'WAS LOADED SUCCESSFULLY',
    PlannedBlList: 'PLANNED BL LIST',
    LastReception: 'LAST RECEPTION',
    LastDispatch: 'LAST DISPATCH',
    Unoccupied: 'UNOCCUPIED',
    Ocupados: 'OCCUPIED',
    YardTeus: 'YARD TEUS',
    PortSituation: 'PORT SITUATION',
    dispached: 'DISPACHED',
    ContainerEquipment: 'CONTAINER',
    VesselsAtBerth: 'VESSELS AT BERTH',
    InDock: 'IN DOCK',
    TruckWeighingsModality: "TRUCKWEIGHING'S MODALITY",
    TheDateCannotBeLessThanTheDateOfDispatch: 'THE DATE CANNOT BE LESS THAN THE DATE OF DISPATCH',
    Cabotage: 'CABOTAGE',
    Recognized: 'CUSTOM INSPECTION',
    Despachados: 'DISPATCHED',
    Recepcionados: 'RECEIVED',
    received: 'RECEIVED',
    Stored: 'STORED',
    YardSituation: 'SITUATION',
    TeusCapacity: 'TEUS CAPACITY',
    TeusAvailable: 'TEUS AVAILABLE',
    TeusOccupied: 'TEUS OCCUPIED',
    ListInvalidPositionContainer: 'LIST OF INVALID POSITION CONTAINER',
    ErrorPleaseCheck: 'THE DATE AND TIME CANNOT BE LESS OR EQUAL THAN',
    ErrorYearOneCurrentDatePleaseCheck: 'THE DATE AND TIME SHOULD NOT BE LESSER OR EQUAL TO THE DATE AT LEAST ONE YEAR FROM THE CURRENT DATE, PLEASE VERIFY',
    RepositionedContainers: 'REPOSITIONED CONTAINERS',
    SelectedContainers: 'SELECTED CONTAINERS',
    ErrorAtdDatePleaseCheck: 'THE DATE AND TIME CANNOT BE GREATER OR EQUAL THAN ATA, PLEASE CHECK',
    ErrorAtaDatePleaseCheck: 'THE DATE AND TIME CANNOT BE LESS OR EQUAL THAN ATA, PLEASE CHECK',
    AtLeastContainerRequired: 'AT LEAST 1 CONTAINER IS REQUIRED',
    DispachesMassiveToVessel: 'DISPATCHES MASSIVE TO VESSEL',
    SealAssignmentList: 'SEAL ASSIGNMENT LIST',
    SealAssignment: 'SEAL ASSIGNMENT',
    MaxImage: 'MAX IMAGE',
    ReceptionCanceledContainers: 'RECEPTION OF CANCELED CONTAINERS',
    CanceledContainers: 'CANCELED CONTAINERS',
    TransacAcronym: 'TXN',
    ExternalYardReception: 'EXTERNAL YARD RECEPTION',
    LastTransaction: 'LAST TRANSACTION',
    ExternYard: 'EXTERN YARD',
    Entry: 'ENTRY',
    MissingBranchId: 'MISSING BRANCH ID',
    YardNotConfiguationAssigned: 'THE YARD DOES NOT HAVE A CONFIGURATION ASSIGNED',
    RecognitionZone: 'CUSTOM INSPECTION ZONE',
    RecognitionDate: 'CUSTOM INSPECTION DATE',
    RecognitionUser: 'CUSTOM INSPECTION USER',
    DispatchUser: 'DISPATCH USER',
    NewEntry: 'NEW ENTRY',
    ErrorReceptionDatePleaseCheck: 'THE DATE AND TIME MUST NOT BE GREATER OR EQUAL THAN RECEPTION DATE, PLEASE CHECK',
    ErrorConfirmationDatePleaseCheck: 'THE DATE AND TIME CANNOT BE LESS OR EQUAL THAN CONFIRMATION DATE, PLEASE CHECK',
    ErrorDispatchDatePleaseCheck: 'THE DATE AND TIME MUST NOT BE GREATER OR EQUAL THAN DISPATCH DATE, PLEASE CHECK',
    ErrorLastMovementDatePleaseCheck: 'THE DATE AND TIME MUST NOT BE GREATER OR EQUAL THAN LAST MOVEMENT DATE, PLEASE CHECK',
    ViewContainer: 'VIEW CONTAINER',
    Measures: 'MEDIDAS',
    RecognitionList: 'CUSTOM INSPECTION LIST',
    ListOfDispatchedContainer: 'LIST OF DISPATCHED CONTAINER',
    Pending: 'PENDING',
    DocumentationOfTicketOut: 'DOCUMENTATION OF TICKET OUT',
    EirDocumentation: 'EIR DOCUMENTATION',
    ListOfDrivers: 'LIST OF DRIVERS',
    VehiclesList: 'VEHICLES LIST',
    OversizedMovement: 'OVERSIZED MOVEMENT',
    Auxiliary: 'AUXILIARY',
    PositioningDate: 'POS. DATE',
    ListOfReceivedContainers: 'LIST OF RECEIVED CONTAINERS',
    ListOfReceivedBls: 'LIST OF RECEIVED BLS',
    Area: 'AREA',
    ReceptionUser: 'RECEPTION USER',
    DaysInYard: 'DAYS IN YARD',
    ReceptionDate: 'DATE RECEPTION',
    ContainersInYard: 'CONTAINERS IN YARD',
    TheDateCannotBeLessThanTheDateOfReception: 'THE DATE CANNOT BE LESS THAN THE DATE OF RECEPTION',
    TheDateCannotBeLessThanTheDateOfConfirmation: 'THE DATE CANNOT BE LESS THAN THE DATE OF CONFIRMATION',
    AtLeastSealRequired: 'AT LEAST 1 SEAL IS REQUIRED',
    TheRoleDoesNotHaveMenuAssigned: 'THE ROLE DOES NOT HAVE A MENU ASSIGNED',
    OrdersByLapse: 'ORDERS BY SHIFT',
    Agency: 'AGENCY',
    ToLoad: 'TO LOAD',
    AveragePerBin: 'AVERAGE PER BIN',
    Accumulated: 'ACCUMULATED',
    LoadedPerTurns: 'LOADED PER TURNS',
    IncidenceName: 'INCIDENCE NAME',
    BinName: 'BIN NAME',
    DeviceCode: 'DEVICE CODE',
    Slot: 'SLOT',
    CargoStatus: 'CARGO STATUS',
    PlaceOfDelivery: 'PLACE OF DELIVERY',
    CargoOriginPort: 'CARGO ORIGIN PORT',
    TransshipmentPortCode: 'TRANSSHIPMENT PORT CODE',
    TransshipmentPort: 'TRANSSHIPMENT PORT',
    IsoInfo: 'ISO INFO',
    GroupType: 'GROUP TYPE',
    SizeFt: 'SIZE (FT)',
    DangerousInfo: 'DANGEROUS INFO',
    UnNumber: 'UN NUMBER',
    IMDGPageNO: 'IMDG PAGE NO',
    FlashPoint: 'FLASH POINT',
    PackagingGroup: 'PACKAGING GROUP',
    oversize: 'OVERSIZE',
    OversizeInfo: 'OVERSIZE INFO',
    DimFront: 'DIM FRONT',
    DimBack: 'DIM BACK',
    DimLeft: 'DIM LEFT',
    DimRight: 'DIM RIGHT',
    DimTop: 'DIM TOP',
    DimFrontMov: 'FRONT',
    DimBackMov: 'BACK',
    DimLeftMov: 'LEFT',
    DimRightMov: 'RIGHT',
    DimTopMov: 'TOP',
    reefer: 'REEFER',
    ReeferInfo: 'REEFER INFO',
    ReeferSetting: 'REEFER SETTING',
    MinimumRange: 'MINIMUM RANGE',
    MaximumRange: 'MAXIMUM RANGE',
    ModifyShipmentOrder: 'MODIFY SHIPMENT ORDER',
    HoldHatchCover: 'HOLD HATCH COVER',
    BABOR: 'PORT',
    CENTRO: 'CENTRO',
    ESTRIBOR: 'STARBOARD',
    Underdeck: 'UNDER DECK',
    TierRows: 'TIER-ROWS',
    RowsToStartboard: 'ROWS TO STARTBOARD',
    RowsToPort: 'ROWS TO PORT',
    Disabled: 'DISABLED',
    CrosssLine: 'CROSS LINE',
    Confirm: 'CONFIRM',
    Hazard: 'HAZARD',
    Hazard_Restriction: 'HAZARD RESTRICTION',
    hazardClass:'HAZARD CLASS',
    unNumber:'UN NUMBER',
    Not_Exist: 'NOT_EXIST',
    Reefer_Plug: 'REEFER PLUG',
    EstimatedBin: 'ESTIMATED BIN',
    Inactivate: 'INACTIVATE',
    BinReturn: 'BIN RETURN',
    IncidenceGroup: 'INCIDENCE GROUP',
    IncidentList: 'INCIDENCE LIST',
    FullLessTare: 'FULL WEIGHT CAN NOT BE LESS THAN TARE WEIGHT',
    ValueNotNegative: 'VALUE CAN NOT BE NEGATIVE',
    CapacityEstimated: 'CAPACITY ESTIMATED',
    BinTareweightlist: 'BIN TARE WEIGHT LIST',
    TareWeightlist: 'TARE WEIGHT LIST',
    BinOrderNoList: 'BIN ORDER NO. LIST',
    OrderNolist: 'ORDER NO. LIST',
    TareWeightAssociateBinMustSelected: 'TARE WEIGHT ASSOCIATE TO BIN MUST BE SELECTED',
    OrderNoAssociateBinMustSelected: 'ORDER NO. ASSOCIATE TO BIN MUST BE SELECTED',
    FullWeighingHistoricOrderNo: 'FULL WEIGHING HISTORIC OF ORDER NO.',
    TareWeightBinHistoricVehicle: 'TARE WEIGHT BIN HISTORIC - VEHICLE',
    TareWeightBinHistoric: 'TARE WEIGHT BIN HISTORIC',
    BinTareWeight: 'BIN TARE WEIGHT',
    FullWeighingHistoric: 'FULL WEIGHING HISTORIC',
    billOfLadingDocument: 'BILL OF LADING DOCUMENT',
    InspectionsRegistre: 'INSPECTIONS REGISTER',
    TransportCertification: 'TRANSPORT CERTIFICATION',
    YardCertification: 'YARD CERTIFICATION',
    OrdersWeight: 'ORDERS - WEIGHT',
    DateWeighing: 'DATE WEIGHING',
    TotalHoldCertification: 'TOTAL HOLD CERTIFICATION',
    InspectionRange: 'INSPECTION RANGE',
    GeneralSummary: 'GENERAL SUMMARY',
    DetailsOfBinsWeighted: 'DETAILS OF BINS WEIGHTED',
    DetailsOfBinsWeightless: 'DETAILS OF BINS WEIGHTLESS',
    DetailsOfBinsLoaded: 'DETAILS OF BINS LOADED',
    ScaleMovements: 'TRUCK WEIGHING SCALE MOVEMENTS',
    BinsWeighted: 'BINS WEIGHTED',
    BinsWeightless: 'BINS WEIGHTLESS',
    BinsLoaded: 'BINS LOADED',
    WeightCorrection: 'WEIGHT CORRECTION',
    WeighingScaleWeight: 'WEIGHING SCALE WEIGHT',
    InternalClient: 'INTERNAL CLIENT',
    InternalBl: 'INTERNAL BL',
    Detallado: 'Full',
    Tons: 'TONS',
    BinsReturned: 'BINS RETURNED',
    DifferenceWeight: 'DIFFERENCE WEIGHT',
    FirstFullWeight: 'FIRST FULL WEIGHT',
    LastFullWeight: 'LAST FULL WEIGHT',
    MayorFullWeight: 'MAYOR FULL WEIGHT',
    ReturnedDate: 'RETURNED DATE',
    Combustible: 'FUEL OIL',
    OnBoard: 'ON BOARD',
    LastMovement: 'LAST MOVEMENT',
    NetHrs: 'NET HRS',
    OrderIssued: 'ORD. ISSUED',
    TotalIncidenceByTransport: 'TOTAL INCIDENCE BY CARRIER',
    TotalByIncidence: 'TOTAL BY INCIDENCE',
    BulkCarrierShips: 'BULK CARRIER SHIPS',
    ServiceRequest: 'SERVICE REQUEST',
    TotalTonsLoaded: 'TOTAL TONS LOADED',
    Shift: 'SHIFT',
    TonsAveragePerTruck: 'TONS AVERAGE PER TRUCK',
    DateOfBerthing: 'DATE OF BERTHING',
    MetalScrapTon: 'METAL SCRAP TON',
    ShippingLines: 'SHIPPING LINES',
    MovementsPerCrane: 'MOVEMENTS PER CRANE',
    HrsInOPER: 'HRS IN OPER.',
    LoadDischarge: 'LOAD-DISCHARGE',
    EffectiveHours: 'EFFECTIVE HRS',
    HoursDelays: 'DELAYS HRS',
    NetDelayHours: 'NET DELAY HRS',
    StrategicAlliance: 'STRATEGIC ALLIANCE',
    errorsOperativReport: 'VESSEL SELECT DOES NOT HAVE OPERATIONAL REPORT',
    errorsTimeslost_StrategicAlliance: 'VESSEL SELECT DOES NOT HAVE DELAY TIME - STRATEGIC ALLIANCE',
    VesselsGrossIs: "VESSEL'S GROSS TONNAGE IS" ,
    CurrentGrossIs: 'CURRENT GROSS IS',
    DelayCustom: 'DELAY CUSTOM',
    TimePerTypeVessel: 'TIME PER VESSEL TYPE',
    ErrorAtaPleaseCheck: 'DATE AND TIME MUST NOT BE LESS THAN ATA, PLEASE CHECK',
    ErrorEtaPleaseCheck: 'DATE AND TIME MUST NOT BE LESS THAN ETA, PLEASE CHECK',
    ErrorAtdPleaseCheck: 'DATE AND TIME MUST NOT BE GREATER THAN THE ATD, PLEASE CHECK',
    ErrorEtdPleaseCheck: 'DATE AND TIME MUST NOT BE GREATER THAN THE ETD, PLEASE CHECK',
    ErrorFromToPleaseCheck: "DATE 'FROM' MUST NOT BE GREATER OR EQUAL THAN DATE 'TO', PLEASE CHECK",
    ErrorToFromPleaseCheck: "DATE 'TO' MUST NOT BE GREATER OR EQUAL THAN DATE 'FROM', PLEASE CHECK",
    ErrorCurrentDatedPleaseCheck: 'DATE AND TIME MUST NOT BE GREATER THAN CURRENT DATE, PLEASE CHECK',
    ErrorOrderDateIssuePleaseCheck: 'DATE AND TIME MUST NOT BE LESS THAN ORDER ISSUED DATE , PLEASE CHECK',
    ErrorOrderDateFinishPleaseCheck: 'DATE AND TIME MUST NOT BE GREATER THAN ORDER SHIPMENT DATE, PLEASE CHECK',
    UnitMeasurementAssociation: 'UNIT MEASUREMENT ASSOCIATION',
    TotalOrders: 'TOTAL ORDERS',
    Received: 'RECEIVED',
    UniqueId: 'UNIQUE ID',
    TheVisitHasNotStartedOperations: 'THE VISIT HAS NOT STARTED OPERATIONS',
    RelocationGuide: 'RELOCATION GUIDE',
    OpenOrders: 'OPEN ORDERS',
    ClosedOrders: 'CLOSED ORDERS',
    OrdersInactive: 'INACTIVE ORDERS',
    InOperations: 'IN OPERATIONS',
    SeeEntryOrdersByDriver: 'SEE ENTRY ORDERS BY DRIVER',
    SeeShipmentOrdersByDriver: 'SEE SHIPMENT ORDERS BY DRIVER',
    SeeEntryOrdersByVehicle: 'SEE ENTRY ORDERS BY VEHICLE',
    SeeShipmentOrdersByVehicle: 'SEE SHIPMENT ORDERS BY VEHICLE',
    SeeShipmentOrdersByBin: 'SEE SHIPMENT ORDERS BY BIN',
    HourRangesMachineWork: 'HOUR RANGES OF MACHINE WORK',
    HoursRange: 'HOURS RANGE',
    RangesOfCraneWorkingHours: 'RANGES OF CRANE WORKING HOURS',
    VesselSide: 'VESSEL SIDE',
    AnulationDate: 'ANULATION DATE',
    AnulationDeviceCode: 'ANULATION DEVICE CODE',
    AnulationLogin: 'ANULATION LOGIN',
    ObservationAnulation: 'OBSERVATION ANULATION',
    TimeInPort: 'TIME IN PORT',
    AuthoritiesWaitingTime: 'AUTHORITIES WAITING TIME',
    TimeInService: 'TIME IN SERVICE',
    TimeOfInspectionsAndVisitArrival: 'TIME OF INSPECTIONS AND VISIT (ARRIVAL)',
    FreePracticeTimeArrival: 'FREE PRACTICE TIME (ARRIVAL)',
    TimeInDock: 'TIME IN DOCK',
    FreePracticeTimeDeparture: 'FREE PRACTICE TIME (DEPARTURE)',
    OtherTimes: 'OTHER TIMES',
    TimeOfInspectionsAndVisitDeparture: 'TIME OF INSPECTIONS AND VISIT (DEPARTURE)',
    GrossTimeOfOperations: 'GROSS TIME OF OPERATIONS',
    Completed: 'COMPLETED',
    Occurrence: 'OCCURRENCE',
    ActivitiesNotExecuted: 'ACTIVITIES NOT EXECUTED',
    Inactivity: 'INACTIVITY',
    Management: 'MANAGEMENT',
    TimeLine: 'TIME LINE',
    Export: 'EXPORT',
    Import: 'IMPORT',
    Operations: 'OPERATIONS',
    CraneHrs: 'CRANES HRS',
    MovsXHrNet: 'MOVS X HOUR NET',
    MovsXHrGross: 'MOVS X HOUR GROSS',
    MovementsPerHourNet: 'MOVEMENTS PER HOUR NET',
    MovementsPerHourGross: 'MOVEMENTS PER HOUR GROSS',
    MovementsPerHourTerminal: 'MOVEMENTS PER HOUR TERMINAL',
    PortActivities: 'PORT ACTIVITIES',
    MovementsAndInactivityTimePerCrane: 'MOVEMENTS AND INACTIVITY TIMES PER CRANE',
    OrderAffectedList: 'ORDER AFFECTED LIST',
    OverrideOrderAndWeighingEvents: 'OVERRIDE ORDER(S) AND WEIGHING EVENTS',
    OverrideOrder: 'OVERRIDE ORDER(S)',
    OriginBin: 'ORIGIN BIN',
    NoChangesToModify: 'NO CHANGES TO MODIFY',
    EnterContainerCode: 'ENTER CONTAINER CODE',
    EnterBlCode: 'ENTER BL CODE',
    Percentage: 'PERCENTAGE',
    Reefers: 'REEFERS',
    Tank: 'TANK',
    VisitSummary: 'VISIT SUMMARY',
    TheCraneIsNotOperational: 'THE CRANE IS NOT OPERATIONAL',
    Visit: 'VISIT',
    InactivateMachineVisit: 'INACTIVATE MACHINE - VISIT',
    ErrorLog: 'ERROR LOG',
    TransferDate: 'TRANSFER DATE',
    TransferUser: 'TRANSFER USER',
    ErrorMessage: 'ERROR MESSAGE',
    UsersWithPermission: 'USERS WITH PERMISSION',
    UsersTransactions: 'USERS - TRANSACTIONS',
    TabletEnabled: 'TABLE ENABLED',
    TableTransactions: 'TABLE - TRANSACTIONS',
    NumberOfErrors: 'NUMBER OF ERRORS',
    NumberOfTransactions: 'NUMBER OF TRANSACTIONS',
    NumberOfWarningMessages: 'NUMBER OF WARNING MESSAGES',
    ErrorType: 'TYPE ERROR',
    TabletUsageSummary: 'TABLET USAGE SUMMARY',
    ErrorsDistribution: 'ERRORS DISTRIBUTION',
    ClosingOperations: 'CLOSING OPERATIONS',
    Retraso: 'DELAY',
    EstimatedDeparture: 'ESTIMATED DEPARTURE',
    HoursOperating: 'HOURS OPERATING',
    Transferencia: 'TRANSFER',
    Fleet: 'FLEET',
    NoWeighed: 'NO WEIGHED',
    BinEstXCulm: 'BIN EST. X CULM',
    CloseVesselOperationsQuestion: 'ARE YOU SURE TO CLOSE VESSEL OPERATIONS?',
    CloseOperations: 'CLOSE OPERATIONS',
    EndOperationsQuestion: 'ARE YOU SURE TO END OPERATIONS OF THE VISIT?',
    StartOperations: 'START OPERATIONS',
    Berthed: 'BERTHED',
    PlannedTon: 'PLANNED TON',
    CertifiedTon: 'CERTIFIED TON',
    WeighedTon: 'WEIGHED TON',
    RemainingTon: 'REMAINING TON',
    MphAffectation: 'MPH AFFECTATION',
    InoperativeCrane: 'INOPERATIVE CRANE',
    MultipleTime: 'MULTIPLE DELAYS',
    CancelationReason: 'CANCELATION REASON',
    FirstMovement: 'FIRST MOVEMENT',
    RecordUpdatedSuccessFully: 'RECORD UPDATED SUCCESSFULLY',
    MultipleTime: 'MULTIPLE TIME',
    TheDateFromIsRequired: "THE DATE 'FROM' IS REQUIRED",
    TheDateToIsRequired: "THE DATE 'TO' IS REQUIRED",
    TimeIsRequired: 'TIME IS REQUIRED',
    RecordUpdatedSuccessFully: 'RECORD UPDATED SUCCESSFULLY',
    YouMustHaveAtLeastOneHoldPerCrane: 'YOU MUST HAVE AT LEAST 1 HOLD PER CRANE',
    HoursWorked: 'HOURS WORKED',
    PlannedWorked: 'PLANNED HOURS',
    CancelationReason: 'CANCELATION REASON',
    DescriptionOfGoods: 'DESCRIPTION OF GOODS',
    Heading: 'HEADING',
    UMReefer: 'U.M REEFER',
    UMOversize: 'U.M OVERSIZE',
    BaysLot: 'BAYSLOT',
    MachineData: 'MACHINE DATA',
    FirstMovement: 'FIRST MOVEMENT',
    EventList: 'EVENT LIST',
    Event: 'EVENT',
    EventsPerYard: 'EVENTS PER YARD',
    classificationName: 'CLASSIFICATION NAME',
    EquipmentDestination: 'EQUIPMENT DESTINATION',
    DispachedLogistics: 'DISPACHED LOGISTICS',
    AssociateCargoType: 'ASSOCIATE CARGO TYPE',
    AssociateEventsPerYard: 'ASSOCIATE EVENTS PER YARD',
    Procedence: 'PROCEDENCE',
    Continue: 'CONTINUE',
    PlannedHoursCrane: 'PLANNED HOURS OF THE GRANE',
    CurrentSeal: 'CURRENT SEAL',
    NewSeal: 'NEW SEAL',
    AssignedBy: 'ASSIGNED BY',
    MustIndicateVesselOrGraneToMphConfig: 'DEBE INDICAR BUQUE O GRUA PARA LA CONFIGURACIÓN DE MPH',
    Inoperative: 'INOPERATIVE',
    EirNumber: 'EIR NUMBER',
    Welcome: 'BIENVENIDO/A',
    Delivered: 'DELIVERED',
    EquipmentInterchangeReceipt: 'EQUIPMENT INTERCHANGE RECEIPT',
    FullsSeal: "FULL'S SEAL",
    DamageCodes: 'DAMAGE CODES',
    SealShippingLineOrCustomBroker: 'SEAL SHIPPING LINE OR CUSTOM BROKER',
    SealCustomBroker: 'SEAL CUSTOM BROKER',
    PleaseReturnEmptyEquipmentAt: 'PLEASE RETURN THE EMPTY EQUIPMENT AT',
    ReeferEquipmet: 'REEFER EQUIPMET',
    CarrierData: 'CARRIER DATA',
    CheckerData: 'CHECKER DATA',
    DriversReadableName: "DRIVER'S READABLE NAME",
    ReadableName: 'READABLE NAME',
    KeyBoard: 'KEYBOARD',
    Tarpaulin: 'TARPAULIN',
    PackagingBlInformation: "PACKAGIN/BL INFORMATION",
    // vilchez
    navigation:'NAVIGATION',
    OrdersNotWeighed: 'ORDERS NOT WEIGHED',
    InactiveModule: 'INACTIVE FATHER MODULE, IT CANNOT BE EDIT, OR REGISTER A NEW SUDMODULE',
    ModuleName:'MODULE NAME',
    SubModuleName:'SUBMODULE NAME',
    SubModule:'SUBMODULE',
    VersionCode: 'VERSION CODE',
    VersionControlList:'VERSION CONTROL LIST',
    AvailableDate:'AVAILABLE DATE',
    ExpirationDate: 'EXPIRATION DATE',
    DeadLine:'DEADLINE',
    MandatoryDownload:'MANDATORY DOWNLOAD',
    downloadApk:'DOWNLOAD APK',
    ErrorExpirationDateRangeAvalible:' THE EXPIRATION DATE MUST BE GREATER THAN THE AVAILABLE DATE',
    ErrorExpirationDateRangeDeadline:'THE EXPIRATION DATE MUST BE GREATER THAN THE UPDATE DEADLINE',
    ErrorDeadlineDateRangeAvailable:'THE UPDATE DEADLINE MUST BE GREATER THAN AVAILABLE DATE',
    ErrorDescription:'ENTER DESCRIPTION',
    areaType: 'AREA TYPE',
    groupingOfYards:'GROUPING OF YARDS',
    yardGroup:'YARD GROUP',
    height_: 'HEIGHT',
    bulkCargoName: 'BULK CARGO',
    generalCargo: 'GENERAL CARGO',
    areaTypeList: 'AREA TYPE LIST',
    access_: 'ACCESS',
    accessCode: 'ACCESS CODE',
    areaTypeName: 'TYPE AREA NAME',
    viewRelatedYards: 'VIEW RELATED YARDS',
    block: 'BLOCK',
    bays: 'BAYS',
    stack: 'STACK',
    blockStatus: 'BLOCK STATUS',
    blStatus:"BL STATUS",
    blockName: 'BLOCK NAME',
    heightPlan: 'HEIGHT PLAN',
    dateUpdate: 'DATE UPDATE',
    rolesbyVesselType: 'ROLES BY VESSEL TYPE',
    machineClassification: 'MACHINE CLASSIFICATION',
    classificationMachine: 'CLASSIFICATION MACHINE',
    subsystemType: 'SUDSYSTEM TYPE',
    srcOption:'SRC OPTION',
    fatherError:'The parent you selected is incorrect, select another one again',
    exidedOrder:'The order is very exaggerated we recommend a smaller order',
    ordenExists:'This order already exists',
    ordenReselectOrder:'Error to reselect the order',    
    menu:'MENU',
    exampleRoute: 'E.G \ROUTE or /MODULE/ROUTE',
    exampleLocation: 'E.G \PAGES or /INDEX.VUE',
    userAssignedRoles: 'USER ASSIGNED ROLES',
    role:'ROLE',    
    birthDate:'BIRTHDATE',
    incidenceGroupName: 'INCIDENCE GROUP NAME',
    userData:'USER DATA',
    hideTableRolesPass: 'HIDE TABLE ROLES',
    viewTableRolespass: 'VIEW TABLE ROLES',
    availableRoles: 'AVAILABLE ROLES',
    anulationWeighing: 'ANULATION WEIGHING',
    blDocuments: 'BL DOCUMENTS',
    blType: 'BL TYPE',
    blCargoMaster: 'BL MASTER',
    unNumberList: 'UN NUMBER LIST',
    plan: 'PLAN',
    dateRangefilterContainer: 'YOU MUST ENTER DATE RANGE WHEN FILTERING BY CONTAINER',
    eventReasonList: 'EVENT REASON LIST',
    eirType: 'EIR TYPE',
    eventForReason: 'EVENT FOR REASON',
    cancellationDate: 'CANCELLATION DATE',
    receptionToShip: 'RECEPTION TO SHIP',
    returned: 'RETURNED',
    previousPosition: 'PREVIOUS POSITION',
    previousModule: 'PREVIOUS MODULE',
    newPosition: 'NEW POSITION',
    InspectionsList: 'INSPECTIONS LIST',
    eventGeneralList: 'EVENT GENERAL LIST',
    inspectionGeneralList: 'INSPECTION GENERAL LIST',
    equipmetToPosition: 'EQUIPMENT TO POSITION',
    eirIssuedList: 'EIR ISSUED LIST',
    deliverySector: 'DELIVERY SECTOR',
    internal: 'INTERNAL',
    external: 'EXTERNAL',
    dimension: 'DIMENSION',
    hsCode: 'HS CODE',
    associateCommodity: 'ASSOCIATE COMMODITY',
    associateGeneralGroupCargoCommodity: 'ASSOCIATE GENERAL GROUP CARGO COMMODITY',
    dischargeConfirmation: 'DISCHARGE CONFIRMATION',
    remainder: 'REMAIN',
    firstEvent: 'FIRST EVENT',
    dateFierstEvent:"FIRST EVENT DAY",
    LastEvent:"LAST EVENT",
    DateLastEvent:"LAST EVENT DAY",
    daysInPort:"DAYS IN PORT",
    stowageStatus:"STOWAGE STATUS",
    blsPendingReceive:"BLS PENDING TO RECEIVE",
    maxLengthDeclared: 'THIS VALUE EXCEEDS THE DECLARED VALUE',
    IncorrectVolumenValue: 'THIS VALUE DOES NOT MATCH VOLUME IN SERIALIZATION',
    IncorrectVolumenWeightValue: 'THIS VALUE DOES NOT MATCH WEIGHT IN SERIALIZATION',
    pendingReceive:"PENDING TO RECEIVE",
    PendingReceiveInYard:"BLS PENDING TO RECEIVE IN YARD",
    statusYardWarehouse:"STATUS IN YARD / WAREHOUSE",
    blsinyard:"BLS IN YARD",
    equipmentConfirmed: 'THIS EQUIPMENT HAS ALREADY BEEN CONFIRMED',
    dischargeQuantity: 'DISCHARGED QUANTITY',
    discharged: 'DISCHARGED',
    plannedQuantity: 'PLANNED QUANTITY',
    consigneeAssignment: 'CONSIGNEE ASSIGNMENT',
    identifiedQuantity: 'IDENTIFIED QUANTITY',
    landedQuantity: 'LANDED QUANTITY',
    quantityRemainErrorBl: 'THE QUANTITY IS GREATER THAN THE REMAINING OF BL',
    quantityRemainError: 'THE QUANTITY IS GREATER THAN THE REMAINING',
    quantityRemainPackaging: 'THE QUANTITY IS GREATER THAN THE REMAINING PACKAGING',
    packagingInformation: 'PACKAGING INFORMATION',
    blInformation: 'BL INFORMATION',
    pendingToDischarge: 'PENDING TO DISCHARGE',
    pendingToIndentify: 'PENDING TO IDENTIFY',
    pendingToShip: 'PENDING TO SHIP',
    assignExporter: 'EXPORTER ASSIGN',
    loadConfirmation: 'LOAD CONFIRMATION',
    lapseDetail: 'DETAIL BY SHIFT',
    lapseSummary: 'SUMMARY BY SHIFT',
    //GENERALES 
    //-----------------------------------------------------------------------------------------------------------
    equipmentDoesNotHaveDeperturePosition:'EQUIPMENT DOES NOT HAVE DEPARTURE POSITION',
    equipmentDoesNotArrive: 'EQUIPMENT DOES NOT HAVE ARRIVE POSITION',
    transactionHistoryOfContainer :'TRANSACTION HISTORY OF CONTAINER',
    positionContainerSlot: 'POSITION CONTAINER',
    auxiliaryZone: 'AUXILIARY ZONE',
    positionedContainer: 'POSITIONED CONTAINERS',
    notValidMovemenet: 'NOT VALID MOVEMENT',
    auxiliaryArea: 'AUXILIARY AREA',
    youMustChooseABlockAndBay: 'YOU MUST CHOOSE A BLOCK AND AT LEAST ONE BAY',
    massivePositioning: 'MASSIVE POSITIONING',
    aSelectedVisitIsRequired: 'IT IS REQUIRED TO HAVE A SELECTED VISIT OR GAMELA',
    maximunFourSeals:'MAXIMUM FOUR SEALS',
    minimunValue10:'MINIMUM VALUE 10.00',
    statusBlock:'STATUS BLOCK',
    habilited:'HABILITED',
    assignconditions: 'ASSIGN CONDITIONS',
    generatoPlan:'GENERATE PLAN',
    specialContainer:'SPECIAL CONTAINER',
    exceededArea: 'THE AREA EXCEEDS 50.000,00 M²',
    teusCapacity: 'TEUS CAPACITY',
    eirPendingTobeIssuedList: 'EIR PENDING TO BE ISSUED LIST',
    equipmentType: 'EQUIPMENT TYPE',
    viewEir: 'VIEW EIR',
    noImages: 'NO IMAGES',
    editYardConfiguration: 'EDIT YARD CONFIGURATION',
    newYardConfiguration: 'NEW YARD CONFIGURATION',
    inventoriesOfContainersInThePort:'INVENTORIES OF CONTAINERS IN THE PORT',
    isItOutOfTime: 'IS IT OUT OF TIME?',
    currentPosition: 'CURRENT POSITION',
    errorDatabasicBl: 'THERE ARE ERRORS IN THE BASIC DATA TAB',
    errorConsigneeBl: 'THERE ARE ERRORS IN THE CONSIGNEE TAB',
    errorDescriptionOfGoodsBl: 'THERE ARE ERRORS IN THE INVENTORY CONTAINS TAB',
    government: 'GOVERNMENT',
    private: 'PRIVATE',
    customsRegimeList: 'CUSTOMS REGIMEN LIST',
    accessTypeList: 'ACCESS TYPES LIST',
    consigneeName: 'CONSIGNEE NAME',
    consigneeRepresentativeName: 'CONSIGNEE REPRESENTATIVE NAME',
    consigneeAddress: 'CONSIGNEE ADDRESS',
    classes: 'CLASSES',
    customsRegimeName: 'CUSTOMS REGIME NAME',
    accessTypeName: 'ACCESS TYPE NAME',
    specialEquipments: 'SPECIAL EQUIPAMENTS',
    emptyReturn: 'EMPTY RETURN',
    wire: 'WIRE',
    Tarpaulin: 'TARPAULIN',
    TarpaulinCondition: 'CANVAS CONDITION',
    specials: 'SPECIALS',
    Delivered: 'DELIVERED',
    EquipmentInterchangeReceipt: 'EQUIPMENT INTERCHANGE RECEIPT',
    FullsSeal: "FULL'S SEAL",
    DamageCodes: 'DAMAGE CODES',
    SealShippingLineOrCustomBroker: 'SEAL SHIPPING LINE OR CUSTOM BROKER',
    SealCustomBroker: 'SEAL CUSTOM BROKER',
    PleaseReturnEmptyEquipmentAt: 'PLEASE RETURN THE EMPTY EQUIPMENT AT',
    ReeferEquipmet: 'REEFER EQUIPMET',
    CarrierData: 'CARRIER DATA',
    CheckerData: 'CHECKER DATA',
    DriversReadableName: "DRIVER'S READABLE NAME",
    ReadableName: 'READABLE NAME',
    KeyBoard: 'KEYBOARD',
    Tarpaulin: 'TARPAULIN',
    CurrentArea: 'CURRENT AREA',
    listOfAlerts : 'LIST OF ALERTS',
    plan: 'PLAN',
    returnToReceptionList: 'RETURN TO RECEPTION LIST',
    whatActionWantPerformContainer: 'WHAT ACTION DO YOU WANT TO PERFORM WITH THE CONTAINER',
    position: 'POSITION',
    positioningList:'POSITIONING LIST',
    containerNotPositioned:"CONTAINER NOT POSITIONED",
    listContainersNotPositioned:'LIST OF CONTAINERS NOT POSITIONED',
    notPositioned:'NOT POSITIONED',
    minimunValue10:'MINIMUM VALUE 10.00',
    statusBlock:'STATUS BLOCK',
    habilited:'HABILITED',
    assignconditions: 'ASSIGN CONDITIONS',
    generatoPlan:'GENERATE PLAN',
    specialContainer:'SPECIAL CONTAINER',
    containerNotFount:'CONTAINER NOT FOUND',
    itTheConsolidator: 'IS IT THE CONSOLIDATOR?',
    consolidator:'CONSOLIDATOR',
    nEir: 'N° EIR',
    nTicketOut : 'N° TICKET OUT',
    eirOfOut: 'EIR OF OUT',
    nroEirSiget:'N° EIR SIGET',
    technicalAspects: 'TECHNICAL ASPECTS',
    actualStatus:'ACTUAL STATUS',
    receptionDocument: 'RECEPTION DOCUMENT', 
    isThisTheConsolidator: 'IS THIS THE CONSOLIDATOR?',
    newSerial: 'NEW SERIAL?',
    destinationContainer: 'DESTINATION CONTAINER',
    bolipuertoReceptionRecordNro:'BOLIPUERTOS ACT RECEPTION NUMBER',
    customsRegime: 'CUSTOMS OPERATION',
    nDua: 'N° DUA',
    nBooking: 'Nº BOOKING',
    shippingLineCode: 'SHIPPING LINE CODE',
    containerType: 'CONTAINER TYPE',
    pluggedIn:'PLUGGED IN',
    plugged:'PLUGGED',
    ContainerPositioning:'CONTAINER POSITIONING',
    ticketOut: 'TICKET OUT',
    newContainerReception: 'NEW CONTAINER RECEPTION',
    internalTransfer:"INTERNAL TRANSFER",
    externalWarehouse:"EXTERNAL WAREHOUSE",
    receiptList:"RECEPTION LIST",
    emptying: "EMPTYING",
    fill:"FILL",
    BlReception: 'BL RECEPTION',
    newBlReception: 'NEW BL RECEPTION',
    editContainerReception: 'EDIT CONTAINER RECEPTION',
    editBlReception: 'EDIT BL RECEPTION',
    ContainerDispatch: 'CONTAINER DISPATCH',
    containerPositioning: 'CONTAINER POSITIONING',
    editContainerPositioning: 'EDIT CONTAINER POSITIONING',
    events: 'EVENTS',
    event: 'EVENT',
    newEvent: 'NEW EVENT',
    eventDate:'EVENT DATE',
    lastMovementDate:'LAST MOVEMENT DATE',
    documentation: 'DOCUMENTATION',
    currentSituationYard:'CURRENT SITUATION',
    yardGeneralState:'YARD GENERAL STATE',
    inventoryMassiveLoad:'INVENTORY MASSIVE LOAD',
    receptionInYard:'RECEPTION IN YARD',
    DaysInCountry:'DAYS IN THE COUNTRY',
    plannedPosition:'PLANNED POSITION',
    cancelationDate:'CANCELATION DATE',
    shipmentConfirmationDate:'SHIPMENT CONFIRMATION DATE',
    dispatchList:'DISPATCH LIST',
    inventoryList: 'INVENTORY LIST',
    massiveListOfEquipmentReception:'MASSIVE LIST OF PENDING EQUIPMENT FOR RECEPTION',
    massiveListEquipmentDispatch:'MASSIVE LIST OF PENDING EQUIPMENT FOR DISPATCH',
    dinamicReport:'DYNAMIC REPORTS',
    dispatchContainer:'DISPATCH',
    ReceptionsVisit:"RECEPTIONS VISIT",
    PendingReceptions: 'PENDING RECEPTIONS',
    masive:'MASIVE',
    reception:'RECEPTION',
    dispatchs:'DISPATCHS',
    dispatch:'DISPATCH',
    alpha: 'ALPHA',
    yardList:'YARD LIST',
    teus: 'TEUS',
    occupiedTeus:'OCCUPIED TEUS',
    availableTeus:'AVAILABLE TEUS',
    lastEntry:'LAST ENTRY',
    lastDespatch:'LAST DISPATCH',
    yardType: 'YARD TYPE',
    block:'BLOCK',
    visit:'VISIT',
    atd:'ATD',
    booking: 'BOOKING',
    customBroker:'CUSTOM BROKER',
    receptionEirNo:'RECEPTION EIR No',
    originVisit:'VISIT ORIGIN',
    floorPlan:'FLOOR PLAN',
    planimetry:'PLANIMETRY',
    locateEquipment:'SEARCH CONTAINER',
    todayEntry:'TODAY ENTRY',
    todayExit:'TODAY EXIT',
    assignedPosition:'ASSIGNED POSITION',
    assignedModule:'ASSIGNED MODULE',
    govermentPrivate:"GOVERNMENT / PRIVATE",
    daysInYard:"DAYS IN YARD",
    originPosition:'ORIGIN POSITION',
    nticket: 'N° TICKET',
    nEir: 'N° EIR',
    dua: 'DUA',
    sidunea:'SIDUNEA',
    dispatchDate: 'DISPATCH DATE',
    dateLastOperation: 'DATE LAST OPERATION',
    nTicketOut : 'N° TICKET OUT',
    eirOfOut: 'EIR OF OUT',
    nroEirBolipuertos:'NO EIR BOLIPUERTOS',
    nroEirSiget:'N° EIR SIGET',
    nBooking: 'Nº BOOKING',
    technicalAspects: 'TECHNICAL ASPECTS',
    sequence: 'SEQUENCE',
    activity: 'ACTIVITY',
    initials: 'INITIALS',
    typecargocode: 'TYPE CARGO CODE',
    acronym: 'ACRONYM',
    required: 'Field Required ',
    onlyText: 'Only Text',
    onlyCoordinate: 'Only Coordinates',
    onlyAlphanumeric: 'Only Alphanumeric',
    onlyNumber: 'Only Numbers',
    onlyDecimal: 'Only Decimals',
    onlyUppercase: 'Only Uppercase.',
    integer: 'only interger',
    minValueIs: 'min value is',
    maxValueIs: 'max value is',
    metrics: 'ONLY METRICS',
    filter: 'FILTER',
    filtro: 'FILTER',
    file: 'FILE',
    number: 'NUMBER',
    lang: 'ENGLISH',
    yard: 'LOCATION',
    toSelect: 'SELECT ',
    select: 'SELECT ',
    selected: 'SELECTED ',
    selectedLogo: 'SELECTED LOGO',
    option: 'OPTION ',
    load: 'LOADING',
    process: "PROCESS",
    success: "SUCCESS",
    error: "ERROR",
    changePass: 'Change your Password',
    moduleInfo: 'Module Info',
    nueva: 'NEW ',
    nuevo: 'NEW ',
    modelBrand: 'BRAND/MODEL',
    model: 'MODEL',
    modelName: 'MODEL NAME',
    brand: 'BRAND',
    brandName: 'BRAND NAME',
    brands: 'BRANDS',
    name: 'NAME',
    lastName: 'LAST NAME',
    registerName: 'NAME REGISTER',
    register: 'REGISTER ',
    clearFields: 'CLEAR FIELDS',
    cleanInformation: 'CLEAN INFORMATION',
    add: 'ADD ',
    edit: 'EDIT ',
    delete: 'DELETE ',
    Remove: 'REMOVE',
    badImage: 'VALID IMAGE REQUIRED',
    needValidImage: 'NEED A VALID IMAGE',
    picture: 'PICTURE',
    crane: 'CRANE',
    type: 'TYPE',
    list: 'LIST',
    work: 'WORK',
    movements: 'MOVEMENTS',
    movement: 'MOVEMENT',
    supplies: 'SUPPLIES',
    Supplies: 'SUPPLIES',
    supplie: 'SUPPLIE',
    supply: 'SUPPLY',
    turn: 'TURN',
    stowage: 'STOWAGE',
    vessel: 'VESSEL',
    role: 'ROLE',
    must: 'YOU MUST ',
    transaction: 'TRANSACTION',
    FINALIZADO: 'FINISHED',
    CANCELADO: 'CANCELED',
    ACTIVO: 'ACTIVE',
    INACTIVO: 'INACTIVE',
    USUARIO: 'USER',
    FECHA: 'DATE',
    group: 'GROUP',
    min: 'REQUIRED MIN ',
    max: 'REQUIRED MAX ',
    chars: ' CHARS',
    change: 'CHANGE ',
    code: 'CODE',
    open: 'OPEN',
    close: 'CLOSE',
    watch: 'WATCH',
    hold: 'HOLD',
    from: 'FROM',
    to: 'TO',
    preselected: 'PRESELECTED',
    holdAssigned: 'HOLD ASSIGNED',
    country: 'COUNTRY',
    documents: 'DOCUMENTS',
    documentation: 'DOCUMENTATION',
    invalid: 'Field Invalid. ',
    capacity: 'CAPACITY',
    grain: ' GRAIN ',
    bail: ' BAIL ',
    deck: 'DECK',
    finish: 'FINISH',
    operative: 'OPERATIVE',
    operational: 'OPERATIONAL',
    operativa: "OPERATIONAL",
    range: 'RANGE',
    description: 'DESCRIPTION',
    return: 'RETURN',
    port: 'PORT',
    location: 'LOCATION',
    localizacion: "LOCATION",
    state: 'STATE',
    latitude: 'LATITUDE',
    longitude: 'LONGITUDE',
    berthName: 'BERTH NAME',
    condition: 'CONDITION ',
    conditions: 'CONDITIONS ',
    draft: 'DRAFT',
    mooring: 'MOORING BOLLARDS',
    service: 'SERVICE',
    serviceList: 'SERVICE LIST',
    serviceCode: 'SERVICE CODE',
    itinerary: 'ITINERARY',
    line: 'LINE',
    see: 'SEE ',
    exit: 'EXIT',
    all: 'ALL',
    ton: 'TON',
    affiliate: 'AFFILIATE ',
    frecuency: 'FRECUENCY',
    reset: 'RESET ',
    table: 'TABLE ',
    observation: 'OBSERVATION',
    voyage: 'VOYAGE',
    voyageNumber: 'VOYAGE NUMBER',
    company: 'COMPANY',
    branch: 'BRANCH',
    object: 'OBJECT',
    municipality: 'MUNICIPALITY',
    parish: 'PARISH',
    address: 'ADDRESS',
    client: 'CLIENT',
    contact: 'CONTACT',
    save: 'SAVE CHANGES',
    level: 'LEVEL',
    module: 'MODULE',
    occupiedModules: 'OCCUPIED MODULES',
    availableModules: 'AVAILABLE MODULES',
    unitsStorage: 'UNITS IN STORAGE',
    order: 'ORDER',
    view: 'VIEW',
    viewName: 'VIEW NAME',
    toView: 'VIEW',
    preview: 'PREVIEW',
    permission: 'PERMISSIONS',
    assign: 'ASSIGN ',
    supplier: 'SUPPLIER',
    quantity: 'QUANTITY',
    representative: 'REPRESENTATIVE',
    resource: 'RESOURCE',
    resources: 'RESOURCES',
    or: 'or',
    status: 'STATUS',
    applies: 'APPLIES',
    Applies: 'APPLIES',
    AppliesTo: 'APPLIES TO',
    apply: 'APPLY',
    IMO: 'IMO',
    yes: 'YES',
    no: 'NO',
    accesory: 'ACCESORY',
    accesories: 'ACCESORIES',
    component: 'COMPONENT',
    components: 'COMPONENTS',
    standar: 'STANDAR',
    january: 'JANUARY',
    february: 'FEBRUARY',
    march: 'MARCH',
    april: 'APRIL',
    may: 'MAY',
    june: 'JUNE',
    july: 'JULY',
    august: 'AUGUST',
    september: 'SEPTEMBER',
    octuber: 'OCTOBER',
    november: 'NOVEMBER',
    december: 'DECEMBER',
    weight: 'WEIGHT',
    density: 'DENSITY',
    classification: 'CLASSIFICATION',
    classifications: 'CLASSIFICATIONS',
    part: 'PART',
    parts: 'PARTS',
    general: 'GENERAL',
    loading: 'CARGANDO',
    planning: 'PLANNING',
    enter: 'ENTER',
    machine: 'MACHINE',
    machinery: 'MACHINERY',
    machineries: 'MACHINERIES',
    ubication: 'UBICATION',
    year: 'YEAR',
    and: 'AND',
    Amount: 'AMOUNT',
    IdNumber: 'ID NUMBER',
    lessThan: 'LESS THAN',
    greaterThan: 'GREATER THAN',
    size: 'SIZE',
    sizes: 'SIZES',
    back: 'BACK',
    special: 'SPECIAL',
    element: 'ELEMENT',
    volume: 'VOLUME',
    LOAD: 'LOAD',
    DISCHARGE: 'DISCHARGE',
    entity: 'ENTITY',
    FULLLOADLIST: 'FULL LOAD LIST',
    EMPTYLOADLIST: 'EMPTY LOAD LIST',
    damageTypeAcronym: 'DAMAGE TYPE ACRONYMS',
    StateAcronyms: 'STATE ACRONYMS',
    Sender: 'SENDER',
    Recipient: 'RECIPIENT',
    repairs: 'REPAIRS',
    TimeOfPreparation: 'TIME OF PREPARATION',
    ControlReference: 'CONTROL REFERENCE',
    CommunicationAgreement: 'COMMUNICATION AGREEMENT',
    MessageReferenceNumber: 'MESSAGE REFERENCE NUMBER',
    MessageTypeIdentifier: 'MESSAGE TYPE IDENTIFIER',
    MessageTypeVersionNo: 'MESSAGE TYPE VERSION NO',
    MessageTypeReleaseNumber: 'MESSAGE TYPE RELEASE NUMBER',
    ControllingAgency: 'CONTROLLING AGENCY',
    AssociationAssignedCode: 'ASSOCIATION ASSIGNED CODE',
    DocumentNumber: 'DOCUMENT NUMBER',
    MessageType: 'MESSAGE TYPE',
    MessageCompilationTime: 'MESSAGE COMPILATION TIME',
    DischargeVoyageNumber: 'DISCHARGE VOYAGE NUMBER',
    PlaceOfDeparture: 'PLACE OF DEPARTURE',
    EstimatedTimeOfDeparture: 'ESTIMATED TIME OF DEPARTURE',
    NextPortOfCall: 'NEXT PORT OF CALL',
    ActualTimeOfArrival: 'ACTUAL TIME OF ARRIVAL',
    ACTUAL_TIME_DEPARTURE: 'ACTUAL TIME OF ARRIVAL',
    ESTIMATED_TIME_ARRIVAL: 'ACTUAL TIME OF DEPARTURE',
    CarrierCode: 'CARRIER CODE',
    STANDARD_DOCUMENTATION: 'STANDARD DOCUMENTATION',
    VesselCallSign: 'VESSEL CALL SIGN',
    VesselLloydsCode: 'VESSEL LLOYDS CODE',
    IdMeanfTransportIdentification: 'ID OF MEANS OF TRANSPORT IDENTIFICATION',
    VesselName: 'VESSEL NAME',
    LoadingVoyageNumber: 'LOADING VOYAGE NUMBER',
    Containers: 'CONTAINERS',
    InspectionsDone: 'INSPECTIONS DONE',
    ListInspectionsDone: 'LIST OF INSPECTIONS DONE', 
    TransactionDate: 'TRANSACTION DATE',
    damageReport: 'DAMAGE REPORT', 
    ESTIMATEDTIMEOFARRIVAL:'ESTIMATED TIME OF ARRIVAL',
    StowageFactor: 'STOWAGE FACTOR',
    DraftListing: 'DRAFT LISTING',
    TotalCertification: 'TOTAL CERTIFICATION',
    HoldList: 'HOLD LIST',
    CertifiedCapacity: 'CERTIFIED CAPACITY',
    Deadweight: 'DEADWEIGHT',
    PermissibleLoanOn: 'PERMISSIBLE LOAD ON',
    Weighing: 'WEIGHING',
    Taraweight: 'TARE WEIGHT',
    transportDriver: 'TRANSPORT-DRIVER',
    FullWeight: 'FULL WEIGHT',
    DraftInspection: 'DRAFT INSPECTION',
    errorHoldPleaseCheck: 'WEIGHT IS REQUIRED AT LEAST IN A HOLD',
    MachineInformation: 'MACHINE INFORMATION',
  },
  agridTable: {
    // Set Filter
    selectAll: '(Select All)',
    selectAllSearchResults: '(Select All Search Results)',
    searchOoo: 'Search...',
    blanks: '(Blanks)',
    noMatches: 'No matches',

    // Number Filter & Text Filter
    filterOoo: 'Filter...',
    equals: 'Equals',
    notEqual: 'Not equal',
    empty: 'Choose One',

    // Number Filter
    lessThan: 'Less than',
    greaterThan: 'Greater than',
    lessThanOrEqual: 'Less than or equal',
    greaterThanOrEqual: 'Greater than or equal',
    inRange: 'In range',
    inRangeStart: 'to',
    inRangeEnd: 'from',

    // Text Filter
    contains: 'Contains',
    notContains: 'Not contains',
    startsWith: 'Starts with',
    endsWith: 'Ends with',

    // Date Filter
    dateFormatOoo: 'yyyy-mm-dd',

    // Filter Conditions
    andCondition: 'AND',
    orCondition: 'OR',

    // Filter Buttons
    applyFilter: 'Applies',
    resetFilter: 'Reset',
    clearFilter: 'Clear',
    cancelFilter: 'Cancel',

    // Filter Titles
    textFilter: 'Text Filter',
    numberFilter: 'Number Filter',
    dateFilter: 'Date Filter',
    setFilter: 'Set Filter',

    // Side Bar
    columns: 'Columns',
    filters: 'Filters',

    // columns tool panel
    pivotMode: 'Pivot Mode',
    groups: 'Row Groups',
    rowGroupColumnsEmptyMessage: 'Drag here to set row groups',
    values: 'Values',
    valueColumnsEmptyMessage: 'Drag here to aggregate',
    pivots: 'Column Labels',
    pivotColumnsEmptyMessage: 'Drag here to set column labels',

    // Header of the Default Group Column
    group: 'Group',

    // Other
    loadingOoo: 'Loading...',
    noRowsToShow: 'No Rows To Show',
    enabled: 'Enabled',

    // Menu
    pinColumn: 'Pin Column',
    pinLeft: 'Pin Left',
    pinRight: 'Pin Right',
    noPin: 'No Pin',
    valueAggregation: 'Value Aggregation',
    autosizeThiscolumn: 'Autosize This Column',
    autosizeAllColumns: 'Autosize All Columns',
    groupBy: 'Group by',
    ungroupBy: 'Un-Group by',
    resetColumns: 'Reset Columns',
    expandAll: 'Expand All',
    collapseAll: 'Close All',
    copy: 'Copy',
    ctrlC: 'Ctrl+C',
    copyWithHeaders: 'Copy With Headers',
    paste: 'Paste',
    ctrlV: 'Ctrl+V',
    export: 'Export',
    csvExport: 'CSV Export',
    excelExport: 'Excel Export',
    excelXmlExport: 'Export to old Excel (.xml)',

    // Enterprise Menu Aggregation and Status Bar
    sum: 'Sum',
    min: 'Min',
    max: 'Max',
    none: 'None',
    count: 'Count',
    avg: 'Average',
    filteredRows: 'Filtered',
    selectedRows: 'Selected',
    totalRows: 'Total Rows',
    totalAndFilteredRows: 'Rows',
    page: 'Page',
    more: 'More',
    to: 'to',
    of: 'of',
    next: 'Next',
    last: 'Last',
    first: 'First',
    firstPage: 'First Page',
    previous: 'Previous',
    previousPage: 'Previous Page',
    nextPage: 'Next Page',
    lastPage: 'Last Page',  

    // Enterprise Menu (Charts)
    pivotChartAndPivotMode: 'Pivot Chart & Pivot Mode',
    pivotChart: 'Pivot Chart',
    chartRange: 'Chart Range',

    columnChart: 'Column',
    groupedColumn: 'Grouped',
    stackedColumn: 'Stacked',
    normalizedColumn: '100% Stacked',

    barChart: 'Bar',
    groupedBar: 'Grouped',
    stackedBar: 'Stacked',
    normalizedBar: '100% Stacked',

    pieChart: 'Pie',
    pie: 'Pie',
    doughnut: 'Doughnut',

    line: 'Line',

    xyChart: 'X Y (Scatter)',
    scatter: 'Scatter',
    bubble: 'Bubble',

    areaChart: 'Area',
    area: 'Area',
    stackedArea: 'Stacked',
    normalizedArea: '100% Stacked',

    histogramChart: 'Histogram',

    // Charts
    pivotChartTitle: 'Pivot Chart',
    rangeChartTitle: 'Range Chart',
    settings: 'Settings',
    data: 'Data',
    format: 'Format',
    categories: 'Categories',
    defaultCategory: '(None)',
    series: 'Series',
    xyValues: 'X Y Values',
    paired: 'Paired Mode',
    axis: 'Axis',
    navigator: 'Navigator',
    color: 'Color',
    thickness: 'Thickness',
    xType: 'X Type',
    automatic: 'Automatic',
    category: 'Category',
    number: 'Number',
    time: 'Time',
    xRotation: 'X Rotation',
    yRotation: 'Y Rotation',
    ticks: 'Ticks',
    width: 'Width',
    height: 'Height',
    length: 'Length',
    padding: 'Padding',
    spacing: 'Spacing',
    chart: 'Chart',
    title: 'Title',
    titlePlaceholder: 'Chart title - double click to edit',
    background: 'Background',
    font: 'Font',
    top: 'Top',
    right: 'Right',
    bottom: 'Bottom',
    left: 'Left',
    labels: 'Labels',
    size: 'Size',
    minSize: 'Minimum Size',
    maxSize: 'Maximum Size',
    legend: 'Legend',
    position: 'Position',
    markerSize: 'Marker Size',
    markerStroke: 'Marker Stroke',
    markerPadding: 'Marker Padding',
    itemSpacing: 'Item Spacing',
    itemPaddingX: 'Item Padding X',
    itemPaddingY: 'Item Padding Y',
    layoutHorizontalSpacing: 'Horizontal Spacing',
    layoutVerticalSpacing: 'Vertical Spacing',
    strokeWidth: 'Stroke Width',
    offset: 'Offset',
    offsets: 'Offsets',
    tooltips: 'Tooltips',
    callout: 'Callout',
    markers: 'Markers',
    shadow: 'Shadow',
    blur: 'Blur',
    xOffset: 'X Offset',
    yOffset: 'Y Offset',
    lineWidth: 'Line Width',
    normal: 'Normal',
    bold: 'Bold',
    italic: 'Italic',
    boldItalic: 'Bold Italic',
    predefined: 'Predefined',
    fillOpacity: 'Fill Opacity',
    strokeOpacity: 'Line Opacity',
    histogramBinCount: 'Bin count',
    columnGroup: 'Column',
    barGroup: 'Bar',
    pieGroup: 'Pie',
    lineGroup: 'Line',
    scatterGroup: 'X Y (Scatter)',
    areaGroup: 'Area',
    histogramGroup: 'Histogram',
    groupedColumnTooltip: 'Grouped',
    stackedColumnTooltip: 'Stacked',
    normalizedColumnTooltip: '100% Stacked',
    groupedBarTooltip: 'Grouped',
    stackedBarTooltip: 'Stacked',
    normalizedBarTooltip: '100% Stacked',
    pieTooltip: 'Pie',
    doughnutTooltip: 'Doughnut',
    lineTooltip: 'Line',
    groupedAreaTooltip: 'Area',
    stackedAreaTooltip: 'Stacked',
    normalizedAreaTooltip: '100% Stacked',
    scatterTooltip: 'Scatter',
    bubbleTooltip: 'Bubble',
    histogramTooltip: 'Histogram',
    noDataToChart: 'No data available to be charted.',
    pivotChartRequiresPivotMode: 'Pivot Chart requires Pivot Mode enabled.',
    chartSettingsToolbarTooltip: 'Menu',
    chartLinkToolbarTooltip: 'Linked to Grid',
    chartUnlinkToolbarTooltip: 'Unlinked from Grid',
    chartDownloadToolbarTooltip: 'Download Chart',

    // ARIA
    ariaHidden: 'hidden',
    ariaVisible: 'visible',
    ariaChecked: 'checked',
    ariaUnchecked: 'unchecked',
    ariaIndeterminate: 'indeterminate',
    ariaColumnSelectAll: 'Toggle Select All Columns',
    ariaInputEditor: 'Input Editor',
    ariaDateFilterInput: 'Date Filter Input',
    ariaFilterInput: 'Filter Input',
    ariaFilterColumnsInput: 'Filter Columns Input',
    ariaFilterValue: 'Filter Value',
    ariaFilterFromValue: 'Filter from value',
    ariaFilterToValue: 'Filter to value',
    ariaFilteringOperator: 'Filtering Operator',
    ariaColumnToggleVisibility: 'column toggle visibility',
    ariaColumnGroupToggleVisibility: 'column group toggle visibility',
    ariaRowSelect: 'Press SPACE to select this row',
    ariaRowDeselect: 'Press SPACE to deselect this row',
    ariaRowToggleSelection: 'Press Space to toggle row selection',
    ariaRowSelectAll: 'Press Space to toggle all rows selection',
    ariaSearch: 'Search',
    ariaSearchFilterValues: 'Search filter values'
  },
  button: {
    accept: 'ACCEPT',
    modify: 'MODIFY',
    cancel: 'CANCEL',
    confirm: 'CONFIRM',
    exit: 'EXIT',
    preview: 'PREVIEW',
    annul: 'ANNUL',
    return: 'REGRESAR',
  },
  buttonWizard: {
    Next: 'Next',
    Back: 'Back',
    Finish: 'Finish',
  },
  status: {
    ACTIVO: "ACTIVE",
    INACTIVO: "INACTIVE"
  },
  validation:{ 
    MissingInformationBl: 'YOU MUST COMPLETE THE MISSING THE BL MASTER´S INFORMATION',
    RequiredBl: 'AT LEAST ONE BL IS REQUIRED',
    WeightAvailabilityPerDetail: 'THE WEIGHT MUST BE AQUAL TO WHAT IS AVAILABLE PER DETAIL',
    WeightAvailabilityPerPackaging: 'THE WEIGHT MUST BE AQUAL TO WHAT IS AVAILABLE PER PACKAGING',
    QuantityAvailabilityPerDetail: 'THE QUANTITY MUST BE AQUAL TO WHAT IS AVAILABLE PER DETAIL',
    QuantityAvailabilityPerPackaging: 'THE QUANTITY MUST BE AQUAL TO WHAT IS AVAILABLE PER PACKAGING',
    NotDocumentype: 'THIS DOCUMENT TYPE DOES NOT CORRESPOND TO THE BL ACTIVITY',
    RequiredDetail: 'AT LEAST ONE DETAIL IS REQUIRED',
    notAvailableDetail: 'AMOUNT OF TONS TO ASSIGN MUST NOT BE GREATER THAN THE AVAILABILITY OF THE DETAIL',
    notAvailablePackaging: 'AMOUNT OF TONS TO ASSIGN MUST NOT BE GREATER THAN THE AVAILABILITY OF THE PACKAGING',
    POLOrPODEqualCompanyBranchPort: 'POL OR POD MUST BE EQUAL THAN COMPANY BRANCH PORT',
    NotSameAsPLAndPOD: 'THE LOAD PORT AND DISCHARGE CANNOT BE THE SAME',
    NotSameLoadPortAndDeliveryPort: 'THE CARGO ORIGIN PORT AND DELIVERY CANNOT BE THE SAME',
    mayoraCero: 'MUST BE GREATER THAN 0', 
    onlyMayusTextEspecial: 'ONLY TEXT AND CHARS , . - Ñ ´ /',
    UtcZone: "REQUIRED FORMAT +00:00",
    onlyNumeroTresdigitos: 'ONLY NUMBER THREE DIGIT',
    not_siglas: "ACRONYMS ON THE CONTAINER DO NOT FULFILL THE VALIDATION FORMAT",
    notsameAsLoading: "Discharge port cannot be the same as loading port",
    notsameAsDischarge: "Port of loading cannot be the same as the port of discharge ",
    container_size: "The container cannot exceed the maximum size of ",
    container_weight: "The container cannot exceed the maximum weight of ",
    onlyCoordinate: 'Only Coordinates',
    onlyDecimal: 'Only Decimals',
    onlyUppercase: 'Only Uppercase.', 
    needValidImage: 'NEED A VALID IMAGE',
    badImage: 'IMAGE NO VALID',
    min: 'REQUIRED MIN ',
    max: 'REQUIRED MAX ',
    minValueIs: 'min value is',
    maxValueIs: 'max value is',
    number: 'NUMBER',
    verifyFields: 'Verify  fields',
    requiresUm: 'REQUIRES UNIT MEASURE',
    chars: 'CHARS',
    alphaSpecials: 'ONLY TEXT AND CHARS . - / $ & ()',  
    email:'REQUIRED FORMAT MAIL@EXAMPLE.COM',
    maxLength: 'REQUIRED MAX ',
    minLength:'REQUIRED MIN  ',
    metrics: 'ONLY METRICS',
    onlyAlphanumeric: 'Only Alphanumeric',
    onlyNumber: 'Only Numbers',
    onlyNumbers: 'Only Numbers',
    onlyText: 'Only Text', 
    required: 'FIELD REQUIRED',
    integer: 'only interger',
    maxValue: 'max value is',
    minValue: 'min value is',
    textareaSpecials: 'ONLY TEXT AND CHARS , . * + - # / $ ! ()',  
    mobileNotReq: 'REQUIRED FORMAT +584120000000, 0242-0000000 o 02420000000',
    emailToLower:'REQUIRED FORMAT MAIL@EXAMPLE.COM',
    correctValue: 'FIELD REQUIRED',
    alpha: 'ALPHABETICAL CHARACTERS ONLY',
    especiales: 'ONLY ALPHANUMERIC AND CHARS -\/\\.,;@ -#():',
    correctDate: 'REQUIRED FORMAT DD/MM/YYYY',
    checkDigit:'CHECK-DIGIT',
    LaitudeNotReq: 'Only Coordinates',
    metricsWSNotReq: 'Only Coordinates',
    textEspecials: 'ONLY TEXT',  
    alphaNum: 'Only Alphanumeric',
    verifyNumber: 'FIELD REQUIRED',
    siglasAlphanumeric: 'Only Alphanumeric',
    credentials2: 'REQUIRED FORMAT VXXXXXXX',
    validMac: 'INVALID MAC ADDRESS',
    notsameAsOrigin: "Destination port cannot be the same as the origin port",
    notsameAsDestiny: "Port of origin cannot be the same as destination",
    codeDelay: 'ONLY ALPHANUMERIC AND CHARS ( )',
    onlyAlphanumeric2: 'Only Alphanumeric',
    manufactererNotReq: 'ONLY TEXT',
    IMEINotReq: 'ONLY NUMBERS',
    resolution: 'ONLY NUMBERS',
    invalidCode: ' Invalid Code',
    textNotReq: 'Only Text',
    textareaNotReq: 'ONLY TEXT AND CHARS , . * + - # / $ ! ()',
    invalidCodeImdg: 'THE FIRST ACRONYM MUST MATCH THE IMDG CLASS. ONLY NUMBERS AND THE CHARACTER . ',
    alphaNumCustom: 'ONLY NUMBERS AND CHAR -',
    numberId: 'DOCUMENT NUMBER - REQUIRED FORMAT V99999999',
    especiales: 'ONLY ALPHANUMERIC AND CHAR -',
    temperature: 'ONLY NUMBERS',
    notsameAsPol: "POL cannot be the same as the POD",
    notsameAsPod: "POD cannot be the same as POL",
    notAvailableTotal: 'Amount of Tons to Assign must not be greater than the Availability of the Hold',
    notAvailableBl: 'Amount of Tons to Assign cannot be greater than what is available to be assigned per Bl',
    exceedtActualInventory: 'Amount of weight must not be greater than the Availability of the actual inventory',
    exceedEstimatedToShip: 'Amount of weight must not be lower than the estimated to ship',
    nameLength: 'THE WORD EXCEEDS THE NUMBER OF CHARACTERS ALLOWED: 20',
    RangeValidate: 'INVALID DATE RANGE',
    TheDateFromIsRequired: "THE 'DATE FROM' IS REQUIRED",
    onlyAlphaguion: 'ONLY TEXT AND CHARS -',
    alphaNumSpecials2: 'ONLY ALPHANUMERIC AND CHARS - & /',
    notOnlyNumbers: 'ONLY ALPHANUMERIC AND CHARS - & /',
    metricsNotReq: 'ONLY NUMBERS',
    onlyRoute: 'REQUIRED FORMAT URL',
    onlyIcon: 'REQUIRED FORMAT .SVG .PNG .JPG',
    onlyCodeVersion:'ONLY TEXT, NUMBERS AND CHARS . - _',
    onlyRouteTablets: 'ONLY TEXT AND CHARS /',
    onlyText2:'ONLY TEXT AND ACCENTS',
    resolutionFormat:'REQUIRED FORMAT 10.00',
    onlyApkFileName:'REQUIRED FORMAT .APK',
    verifyNumClaseImdg: 'VERIFY IMDG CLASS NUMBER',
    onlyHsCode: 'ONLY TEXT, NUMBERS AND CHARS , . - ',
    DocumentTypeActivity: 'THE TYPE OF DOCUMENT DOES NOT CORRESPOND TO THE ACTIVITY',  
    quantityRemainErrorBl: 'THE QUANTITY IS GREATER THAN THE REMAINING OF BL',
    quantityRemainError: 'THE QUANTITY IS GREATER THAN THE REMAINING',
    NotRubro: 'THIS COMMODITY IS NOT ASSOCIATED WITH AN HEADING',
    CertifiedQuantity: "PLANNED QUANTITY CAN'T BE LESS THAN THE CERTIFIED QUANTITY",
    ConsigneeBl: 'DOES NOT MATCH THE CONSIGNEE OF THE BL',
  },
  developed: {
    by: "Developed by",
  },
}

export {
  en
};