export const yardManagement = {
    namespaced: true,
  	state: {
        yardCollapse : 0,
        BlCollapse: 0,
        tabinfoIndex: 0,
        ContainerTabIndex: 0,
        BlTabIndex: 0,
        tabMovementIndex: 0,
        dropContainerMovement: 0,
        dropBlMovement: 0,
        dropItemEvent:0,
        dropItemReport:0,
        dropItemMovementList:0,
        dropItemMovementReception:0,
        dropItemMovementDispatch:0,
        dropItemInventary:0,
        dropItemInspection:0,
        tabIndex:0,
        isSelect: false,
        isSelectYard: false,
        FgPositioning: false,
        FgReception: false,
        FgBlNavigation: false,
        FgContainerNavigation: false,
        GeneralList: 0,
        loading: false,
        dataContainer: {},
        dataBl: {},
        yardData:{},
        ListReceivedContainers: [],
        ListDispatchedContainers: [],
        ListRecognitionContainers: [],
        containerSelected: '',
        blSelected: '',
        YardSelected: '',
        globalSelectContainer: [],
        globalSelectBl: [],
        ContainerStatusInfo: {},
        yardList: []
    },
    getters: {},
    actions: {},
    mutations: {
       
    }
}